import React from 'react';

import '../Scss/Components/spinner.scss';

export function Spinner(props) {
    const { size } = props;

    let spinnerSize = "spinner-border ";
    switch (size) {
        case 0:
            spinnerSize = "spinner-border-sm ";
        default:
            break;
    }

    return(
        <div className={"spinner-border ml-2 " + spinnerSize} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    );
}