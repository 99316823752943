import React from 'react';
import { Helmet } from 'react-helmet';

import { func_validation, func_data } from '../Functions';

import '../Scss/Components/reactHelmet.scss';

class ReactHelmet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const { urlPath } = this.props;

        let headerObj = func_data.getHeaderDetails(urlPath);
        let pageTitle = func_validation.getObjectPropertyValue(headerObj, "pageTitle");
        let pageDescription = func_validation.getObjectPropertyValue(headerObj, "pageDescription");
        let pageKeyword = func_validation.getObjectPropertyValue(headerObj, "pageKeyword");

        return(
            <Helmet>
                <title>{pageTitle}</title>
                <meta charset="UTF-8" />
                <meta name="description" content={pageDescription} />
                <meta name="keywords" content={pageKeyword} />
                <meta name="author" content="Powzie" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
        );
    }
}

export { ReactHelmet };