import React, { useState, useEffect } from 'react';

import { Route } from "react-router-dom";

import { PZButton, Spinner, ReactHelmet, StoreCard } from 'Components';

import { func_validation, func_url, func_data, func_utilities, getCookie, setCookie } from 'Functions';

import { categoriesConstants } from 'Dataflow/Constants';

import { Dropdown as DropdownTest } from 'semantic-ui-react'

import 'Scss/Components/generalLAyout.scss';



const CATEGORY_LIST = categoriesConstants.generalCategories();

export function GeneralLayout(props) {
    //  Props
    const { pageHeaderText,
            getAdvertisementDetails_requesting, advertisementDetails, 
            countryDetails, getCountryList_requesting,
            isCategoryDropdownHidden, isAdvertisementHidden,
            isoCode, category, advertisementId } = props;

    // Props Validation
    let pageHeaderText_formatted = func_validation.isStringTrimNotEmpty(pageHeaderText)? pageHeaderText: "";
    let isoCode_formatted = func_validation.isStringTrimNotEmpty(isoCode)? isoCode: "WW"; // default to Worldwide
    let category_formatted = func_validation.isStringTrimNotEmpty(category)? category: "All";
    let advertisementId_formatted = func_validation.isStringTrimNotEmpty(advertisementId)? advertisementId: "";

    // States
    const [countryDropdown, setCountryDropdown ] = useState(isoCode_formatted);
    const [countryList, setCountryList] = useState([]);
    const [categoryDropdown, setCategoryDropdown ] = useState(category_formatted);

    // Get Country Details
    let countryName = func_validation.getObjectPropertyValue(countryDetails, "Name");
    let countryImage = func_validation.getObjectPropertyValue(countryDetails, "Image");
    let countryLanguage = func_validation.getObjectPropertyValue(countryDetails, "Language");
    useEffect(() => {
        if (props.getCountryDetails) props.getCountryDetails(isoCode_formatted);
    }, []);

    // Country List
    function setCountryListValidation() {
        const { countryList: countryList_props } = props;
        
        // Country List
        if (func_validation.isBooleanFalseNotNullUndefined(getCountryList_requesting) &&
            func_validation.isArrayNotEmpty(countryList_props) &&
            func_validation.isArrayEmpty(countryList)) {

            let newCountryList = [];
            let arraySize = countryList_props.length;
            for (let i = 0; i < arraySize; i++) {
                let countryName = func_validation.getObjectPropertyValue(countryList_props[i], "Name");
                let isoCode = func_validation.getObjectPropertyValue(countryList_props[i], "ISOCode");

                let item = {
                    key: isoCode,
                    value: isoCode,
                    // image: (isoCode === "WW")? { src: 'https://www.flaticon.com/svg/vstatic/svg/4158/4158458.svg?token=exp=1612527167~hmac=43918da304eac6a17ae188eb25230e1d' }: undefined,
                    // flag: (func_validation.isStringTrimNotEmpty(isoCode))? isoCode.toLowerCase(): isoCode,
                    text: func_validation.capitalizeFirstLetterOfEveryWord(countryName)
                };
                newCountryList.push(item);
            }
            setCountryList(newCountryList);
        }
    }
    useEffect(() => {
        if (props.getCountryList) props.getCountryList();
    }, []);
    useEffect(() => {
        setCountryListValidation();
    }, [getCountryList_requesting]);
 

    // Handle advertisement initialization
    function getAdvertisementDetails() {
        if (props.getAdvertisementDetails && 
            func_validation.isStringTrimNotEmpty(advertisementId_formatted))
            props.getAdvertisementDetails(advertisementId_formatted);
    }
    useEffect(() => { getAdvertisementDetails(); },[]);

    // Handle Redirection
    function handleRedirection(country, category) {
        const { mainUrlPathname, history } = props;

        let location = func_validation.getObjectPropertyValue(history, "location", {});
        let searchQuery = func_validation.getObjectPropertyValue(location, "search", "");

        let countryDropdown_formatted = (func_validation.isStringEqual_caseInsensitive(country, "WW"))? "":"/" + country; 
        let category_formatted = func_validation.isStringTrimNotEmpty(category) &&
                                    (!func_validation.isStringEqual_caseInsensitive(category, "All"))? encodeURI(category):"";

        let mainUrlPathname_formatted = "";
        if (func_validation.isStringTrimNotEmpty(mainUrlPathname)) mainUrlPathname_formatted = mainUrlPathname;
        let url = countryDropdown_formatted + mainUrlPathname_formatted + category_formatted;
        func_url.openUrlInCurrentTab(url);
    }

    function handleAdvertisementRedirection(url) {
        func_url.openUrlInNewTab(url);
    }

    // Loading Store Advertisement
    let isStoreAdLoading = false;
    if (func_validation.isBooleanTrueNotNullUndefined(getAdvertisementDetails_requesting))
        isStoreAdLoading = true;

    // Advertisement Details
    let storeName = func_validation.getObjectPropertyValue(advertisementDetails, "StoreName","Visit Website");
    let storeImage = func_validation.getObjectPropertyValue(advertisementDetails, "StoreImage","");
    let adLink = func_validation.getObjectPropertyValue(advertisementDetails, "AdLink","");
    let adButtonTxt = func_validation.getObjectPropertyValue(advertisementDetails, "ButtonText","Click Here");
    let tooltipTxt = func_validation.getObjectPropertyValue(advertisementDetails, "TooltipText","Check out new updates!");

    return(
        <div className="generalLayout d-flex flex-column align-items-center">
            {/* Webpage Metadata */}
            <ReactHelmet 
                urlPath={func_url.getUrlPathname(props)}
            />

            {/* {func_validation.isObjectEmpty(storeAdvertisementDetails)? (
                <PZButton
                    pzId={"powzie-headerUpdate"}
                    pzButton_className={"generalLayout-headerUpdate"}
                    pzButton_onClick={() => { func_url.openUrlInCurrentTab("https://news.google.com/covid19/map"); }}
                    pzButton_isSecondary
                >
                    Covid-19 UPDATE !
                </PZButton>
            ):""} */}
        
            {/* Header */}
            <div className={"generalLayout-header d-flex flex-column flex-md-row align-items-center justify-content-center"}>
                <div className={"generalLayout-headerTitle text-center pb-3"}>
                    <img className={"generalLayout-headerTitleImage"}
                        src={countryImage}
                        alt={"Country Flag"}
                    />
                    <div className={"generalLayout-headerTitle-text"}>{pageHeaderText_formatted}</div>                    
                </div>

                <div className={"generalLayout-dropdownWrapper"}>

                    {/* Category Selection */}
                    {isCategoryDropdownHidden? "":(
                        <div className={"generalLayout-categoryDropdownWrapper"}>
                            <DropdownTest
                                placeholder='Select Category'
                                fluid
                                search
                                selection
                                defaultValue={categoryDropdown}
                                options={CATEGORY_LIST}
                                onChange={(e, obj) => {
                                    let value = func_validation.getObjectPropertyValue(obj, "value");
                                    handleRedirection(countryDropdown, value); 
                                    setCookie("category", value, { path: "/"});
                                }}
                            />
                        </div>
                    )}

                    {/* Country Selection */}
                    <div className={"generalLayout-countryDropdownWrapper"}>
                        <DropdownTest
                            className={"generalLayout-dropdown"}
                            placeholder='Select Country'
                            fluid
                            search
                            selection
                            defaultValue={countryDropdown}
                            options={countryList}
                            onChange={(e, obj) => {
                                let value = func_validation.getObjectPropertyValue(obj, "value");
                                handleRedirection(value, categoryDropdown); 
                                setCookie("country", value, { path: "/"});
                            }}
                        />
                        {/* <Dropdown className={"generalLayout-dropdown"}
                            options={countryList}

                            searchInputPlaceholder={"Search Country..."}
                            selectedValue_default={countryDropdown}
                            isSearchInputRequired

                            onChange={(country) => { handleRedirection(country, categoryDropdown); }}

                            isScrollBarVisible
                        /> */}
                    </div>
                </div>

                {/* Advertisement */}
                {isAdvertisementHidden? "": (
                    <Route path={["/stores/:category/:storeId/:advertisementNo",
                                "/stores/:category/:advertisementId",
                                "/:isoCode/stores/:category/:storeId/:advertisementNo",
                                "/:isoCode/stores/:category/:advertisementId"]}>

                        {!isStoreAdLoading? (
                            <StoreCard {...props}
                                storeCard_className={"generalLayout-mainStore"}
                                storeCard_imageSrc={storeImage}
                                storeCard_imageAlt={storeName}
                                storeCard_name={storeName}
                                storeCard_description={(
                                    <PZButton
                                        pzButton_className={"generalLayout-mainStore-dealsButton"}
                                        pzButton_onClick={() => { handleAdvertisementRedirection(adLink); }}

                                        pzButton_isSecondary
                                    >
                                        {adButtonTxt}
                                    </PZButton>
                                )}

                                storeCard_storeUrl={adLink}
                                isOpenUrlInNewTab

                                isStoreCardNameClickable isOnHoverBorderShadowHidden

                                tooltipClassName={"generalLayout-mainStore-tooltip " + 
                                                    (func_utilities.isMobile()? "generalLayout-mainStore-tooltip_mobilePosition ": "generalLayout-mainStore-tooltip_nonMobilePosition ")}
                                isTooltipVisible isTooltipPosition_bottom
                                tooltipDescription={tooltipTxt}
                            />
                        ): (<Spinner />)}
                    </Route>
                )}
            </div>
            
            {props.children}
        </div>
    );
}