import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { InputText } from './InputText';

import { func_validation } from '../Functions/func_validations';

import '../Scss/Components/searchBar.scss';
import { func_url } from '../Functions/func_url';
import { getCookie, getCookieValue } from 'Functions';

export function SearchBar(props) {
    const { searchBarClassName, searchBarInputClassName, searchBarInputBoxClassName, searchButtonClassName,
            isDarkBackgroundRequired, 
            isDropdownVisible, searchBarDropdownClassName, searchBar_dropdownOptions, searchBar_dropdownValue,
            searchBar_placeholder, searchBar_ariaLabel, searchBarIcon,
            searchBar_suggestionList, isSearchBarSearchSuggestionsHidden, isSearchButtonHidden, isSearchTermReset } = props;

    // Validate Props
    let dropdownOptions = [];
    if (func_validation.isArrayNotEmpty(searchBar_dropdownOptions)) dropdownOptions = func_validation.cloneArray(searchBar_dropdownOptions);
    let dropdownValue = "";
    if (func_validation.isStringTrimNotEmpty(searchBar_dropdownValue)) dropdownValue = searchBar_dropdownValue;

    const [searchTerm, setSearchTerm] = useState("");
    const [suggestionList, setSuggestionList] = useState([]);
    const [isSearchBarFocused, setSearchBarFocus] = useState(false);

    function updateSuggestionList() {
        const { searchBar_suggestionList } = props;

        if (func_validation.isComparisonOfBothArraysNotEqual(suggestionList, searchBar_suggestionList)) {
            setSuggestionList(searchBar_suggestionList);
        }
    }
    useEffect(() => {
        updateSuggestionList();
    }, [searchBar_suggestionList]);

    function toggleSearchBarFocus(isFocused) {
        setSearchBarFocus(isFocused);

        if (props.searchBar_toggleDarkenBackground)
            props.searchBar_toggleDarkenBackground();
    }

    function handleOnChange(value) {
        setSearchTerm(value);
        handleOnChangeExtended(value);
    }

    function handleOnChangeExtended(value) {
        const { isSearchBarSearchSuggestionsHidden, searchBar_maxTotalSuggestions } = props;

        // Update search suggestions
        if (!isSearchBarSearchSuggestionsHidden &&
            props.searchBar_onChange) 
            props.searchBar_onChange(value, searchBar_maxTotalSuggestions, getCookieValue("country"));
    }

    function handleSearch(searchTerm) {
        const { searchBar_urlRedirection, isUrlRedirectionNotRequired } = props;

        // Reset
        setSearchTerm(searchTerm);
        setSuggestionList([]);
        setSearchBarFocus(false);

        // Redirection
        if (func_validation.isStringTrimNotEmpty(searchTerm) && !isUrlRedirectionNotRequired)
            func_url.openUrlInCurrentTab(searchBar_urlRedirection + encodeURIComponent(searchTerm));
    }

    function resetSearchTerm() {
        if (isSearchTermReset) {
            handleOnChange("");
        }
    }
    useEffect(() => {
        resetSearchTerm();
    }, [isSearchTermReset])

    // Placeholder
    let searchBar_placeholder_formatted = "Search";
    if (func_validation.isStringTrimNotEmpty(searchBar_placeholder)) 
        searchBar_placeholder_formatted = searchBar_placeholder;

    // Aria Label
    let searchBar_ariaLabel_formatted = "Search";
    if (func_validation.isStringTrimNotEmpty(searchBar_placeholder)) 
        searchBar_ariaLabel_formatted = searchBar_ariaLabel;

    // Icon
    let searchBarIcon_formatted = faSearch;
    if (func_validation.isObjectNotEmpty(searchBarIcon))
        searchBarIcon_formatted = searchBarIcon;

    // Search Suggestions
    let suggestionsDisplay = [];
    if (func_validation.isArrayNotEmpty(suggestionList)) {
        suggestionsDisplay = suggestionList.map((suggestionItem, idx) => {
            let name = func_validation.getObjectPropertyValueNotNullUndefined(suggestionItem, "name", "");
            let image = func_validation.getObjectPropertyValueNotNullUndefined(suggestionItem, "image", "");

            return (
                <div key={idx} className={"searchbar-searchSuggestionsItem"}
                    onClick={() => { handleSearch(name); }}
                >
                    {image? (
                        <img
                            className={"searchbar-searchSuggestionsItem-image"}
                            src={image}
                            alt={"Suggestion Image"}
                        />
                    ):""}

                    <span>{name}</span>
                </div>
            );
        });
    }

    // Dropdown
    function handleDropdownOnChange(idx, value) {
        if (props.dropdownOnChange) props.dropdownOnChange(idx, value);
    }

    return(
        <div className={"searchBar d-flex flex-self flex-fill " +
                        (searchBarClassName? searchBarClassName + " ":"")}>

            {/* Backgound */}
            {isSearchBarFocused && isDarkBackgroundRequired? (
                <div className={"searchBar-background"}
                    onClick={() => { toggleSearchBarFocus(false); }}
                />
            ):""}
            
            {/* Search Input */}
            <div className={"searchBar-searchInputWrapper d-flex flex-self flex-fill align-items-center "}>

                {/* Input */}
                <InputText
                    inputText_className={"searchBar-search w-100 " + (searchBarInputClassName? searchBarInputClassName + " ":"")}
                    inputTextInputBoxWrapper_className={"w-100"}
                    inputTextInputBox_className={"searchBar-searchInput w-100 " + 
                        (isDropdownVisible? "searchBar-searchInput_noBorderRadius ":"") + 
                        (searchBarInputBoxClassName? searchBarInputBoxClassName + " ":"")}
                    
                    onClick={() => { toggleSearchBarFocus(true); }}

                    isDropdownVisible={isDropdownVisible}
                    dropdownClassName={"searchBar-dropdown " + (searchBarDropdownClassName? searchBarDropdownClassName + " ":"")}
                    dropdownOptions={dropdownOptions}
                    dropdownValue={dropdownValue}

                    dropdownOnChange={handleDropdownOnChange}

                    InputText_stateName={"searchTerm"}
                    InputText_name={"searchTerm"}
                    InputText_value={searchTerm}

                    inputText_placeholder={searchBar_placeholder_formatted}
                    inputText_AriaLabel={searchBar_ariaLabel_formatted}

                    inputText_onChange={handleOnChange}
                    inputText_onKeyUpEnter={() => { handleSearch(searchTerm); }}

                    inputTextInputBoxExtend={(
                        /* Search Suggestions */
                        (!isSearchBarSearchSuggestionsHidden && 
                        isSearchBarFocused &&
                        func_validation.isStringTrimNotEmpty(searchTerm))? (
                            <div className={"searchBar-searchSuggestions"}>
                                {suggestionsDisplay}
                            </div>
                        ):""
                    )}
                />
                
                {isSearchButtonHidden? "":(
                    <div className={"searchBar-button " + (searchButtonClassName? searchButtonClassName + " ":"")}
                        onClick={() => { handleSearch(searchTerm); }}
                    >
                        <FontAwesomeIcon icon={searchBarIcon_formatted} />
                    </div>
                )}
            </div>

        </div>
    );
}