import React, { useState } from 'react';

import { Dropdown }  from 'Components/Dropdown';

import { func_validation } from '../Functions/func_validations';

import 'Scss/Components/inputText.scss';
import { useEffect } from 'react';

export function InputText(props) {
    const { inputText_className, 
            inputTextInputBoxWrapper_className, inputTextInputBox_className,
            inputText_name, defaultInputValue, 
            inputText_placeholder, inputText_AriaLabel, 
            inputTextInputBoxExtend } = props;
    const { isDropdownVisible, dropdownClassName, dropdownOptions, dropdownValue } = props;

    // Validate Dropdown
    let defaultInputValue_formatted = "";
    if (func_validation.isStringTrimNotEmpty(defaultInputValue)) defaultInputValue_formatted = defaultInputValue;
    let dropdownOptions_formatted = [];
    if (func_validation.isArrayNotEmpty(dropdownOptions)) dropdownOptions_formatted = func_validation.cloneArray(dropdownOptions);
    let dropdownValue_formatted = "";
    if (func_validation.isStringTrimNotEmpty(dropdownValue)) dropdownValue_formatted = dropdownValue;
    let inputTextInputBoxExtend_formatted = "";
    if (func_validation.isNotNullUndefined(inputTextInputBoxExtend)) inputTextInputBoxExtend_formatted = inputTextInputBoxExtend;

    const [inputValue, setInputValue] = useState(defaultInputValue_formatted);

    useEffect(() => {
        if (func_validation.isStringTrimNotEmpty(defaultInputValue)) setInputValue(defaultInputValue);
    }, [defaultInputValue]);

    function handleOnClick() {
        if (props.onClick) props.onClick();
    }

    function handleOnChange(e) {
        const { isInputOnlyPrice } = props;
        const { value } = e.target;

        let value_formatted = value;
        if (props.onChangeValidation) value_formatted = props.onChangeValidation(value);

        if (isInputOnlyPrice) {
            if ((value_formatted.match(/^(\d)*(\.\d{0,2})?$/) || []).length <= 0)
                value_formatted = inputValue;
        }

        setInputValue(value_formatted);

        if (props.inputText_onChange) props.inputText_onChange(value);
    }

    function handleOnBlur(e) {
        const { isOnBlurReset } = props;
        const { value } = e.target;

        let value_formatted = value;
        if (props.onBlurValidation) value_formatted = props.onBlurValidation(value);
        if (isOnBlurReset) value_formatted = "";
        setInputValue(value_formatted);

        if (props.inputText_onBlur) props.inputText_onBlur(value);
    }

    function handleKeyUp(e) {
        const { value } = e.target;

        if ((e.keyCode === 13 &&
            props.inputText_onKeyUpEnter)) {
            props.inputText_onKeyUpEnter(value);
        }
    }

    // Dropdown
    function handleDropdownOnChange(idx, value) {
        if (props.dropdownOnChange) props.dropdownOnChange(idx, value);
    }

    // Placeholder
    let inputText_placeholder_formatted = "Search";
    if (func_validation.isStringTrimNotEmpty(inputText_placeholder)) 
        inputText_placeholder_formatted = inputText_placeholder;

    // Aria Label
    let inputText_AriaLabel_formatted = "Search";
    if (func_validation.isStringTrimNotEmpty(inputText_AriaLabel)) 
        inputText_AriaLabel_formatted = inputText_AriaLabel;

    return(
        <div className={"inputText d-flex " +
                        (inputText_className? inputText_className + " ":"")}
            onClick={handleOnClick}
        >
            {/* Dropdown */}
            {isDropdownVisible? (
                <Dropdown
                    className={"inputText-dropdown " + 
                                (dropdownClassName? dropdownClassName + " ":"")}

                    options={dropdownOptions_formatted}
                    selectedValue_default={dropdownValue_formatted}

                    onChange={handleDropdownOnChange}
                />
            ):""}

            {/* Input */}
            <div className={"inputText-inputWrapper " +
                            (inputTextInputBoxWrapper_className? inputTextInputBoxWrapper_className + " ":"")}>
                <input className={"inputText-input " +
                                    (inputTextInputBox_className? inputTextInputBox_className + " ":"")}
                    name={inputText_name}
                    value={inputValue}

                    type="text" 
                    placeholder={inputText_placeholder_formatted} 
                    aria-label={inputText_AriaLabel_formatted}

                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onKeyUp={handleKeyUp}
                />

                {/* Extend */}
                {inputTextInputBoxExtend}
            </div>
        </div>
    );
}