import React from 'react';

import { func_data } from '../Functions';

class SitemapGenerator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        let headerDetails = func_data.headerDetails;
        
        // Get all the url paths
        let siteMap_content = "";
        let count = 0;
        for (const property in headerDetails) {
            let location = "https://www.powzie.com" + property;

            let dateNow = new Date();
            let dateNow_formatted = dateNow.toISOString();
            let dateNow_final = dateNow_formatted.substring(0, dateNow_formatted.length - 1); // Remove 'Z' at the end
            let lastModified = dateNow_final;

            let priority = 1.0;
            let totalNestedPath = location.match(/\//g).length;
            switch (totalNestedPath) {
                case 2:
                    priority = 1.0; break;
                case 3:
                    priority = 0.9; break;
                case 4:
                    priority = 0.8; break;
                default:
                    break;
            }

            // Generate sitemap content
            if (count > 0) siteMap_content += "\n";
            siteMap_content += "<url>\n\t\t";
            siteMap_content += "<loc>" + location + "</loc>\n\t\t";
            siteMap_content += "<lastmod>" + lastModified + "</lastmod>\n\t\t";
            siteMap_content += "<priority>" + priority + "</priority>\n\t";
            siteMap_content += "</url>";
            count++;
        }

        let openingWrapper = "<urlset xmlns=\"http://www.sitemaps.org/schemas/sitemap/0.9\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd\"><script/>\n\t"
        let closingWrapper = "</urlset>"
        
        return(
            <div>
                {openingWrapper}
                {siteMap_content}
                {closingWrapper}
            </div>
        );
    }
}

export default SitemapGenerator;