import React, { useState, useEffect } from 'react';

import { PZLongModal } from 'Components/PZLongModal';

import { func_validation } from 'Functions';

import 'Scss/Components/notification.scss';

export default function NotificationPresentation(props) {
    let { notificationDetail } = props;

    function getNotificationDetail() {
        if (props.getNotificationDetail) props.getNotificationDetail();
    }
    useEffect(() => {
        getNotificationDetail();
    }, []);

    if (func_validation.isObjectEmpty(notificationDetail)) return "";

    let headerMsg = "", bodyMsg = "";
    let newVersion = 0;
    let currentVersion = 0;
    let isVisible = false;
    if (func_validation.isObjectNotEmpty(notificationDetail)) {
        headerMsg = func_validation.getObjectPropertyValue(notificationDetail, "HeaderMsg");
        bodyMsg = func_validation.getObjectPropertyValue(notificationDetail, "BodyMsg");

        newVersion = func_validation.getObjectPropertyValue(notificationDetail, "WebAppVersion");
        if (func_validation.isStringTrimNotEmpty(newVersion)) newVersion = parseInt(newVersion);
        
        isVisible = func_validation.getObjectPropertyValue(notificationDetail, "isVisible");
        if (func_validation.isStringTrimNotEmpty(isVisible)) isVisible = parseInt(isVisible);
    }

    // Compare version
    if ((!func_validation.isNumberValid(newVersion)) || 
        (newVersion <= currentVersion) ||
        (func_validation.isStringTrimEmpty(headerMsg) && 
            func_validation.isStringTrimEmpty(bodyMsg)) ||
        (!isVisible)) 
        return "";

    return (
        <PZLongModal
            modalWrapperClassName={"notification"}
            pzLongModal_header={headerMsg}
            isModalOpen
        >
            <div className="notification-msg d-flex align-items-center">
                {bodyMsg}
            </div>
        </PZLongModal>
    );
}