import React from 'react';
import { useParams } from "react-router-dom";

import 'Scss/Modules/products.scss';
import { SearchBar } from 'Components/SearchBar';
import { func_validation } from 'Functions';

export default function ProductsPresentation(props) {
    const { productNameList } = props;

    const { isoCode, category } = useParams();
    let isoCodePath = func_validation.isStringTrimNotEmpty(isoCode)? "/" + isoCode:"";
    let categoryPath = func_validation.isStringTrimNotEmpty(category)? "/" + category:"";

    return (
        <div className={"products d-flex justify-content-center align-items-center"}>
            <div className={"products-wrapper"}>

                <div className={"products-header"}>
                    Search &amp; Compare
                </div>

                <div className={"products-subheader text-center"}>
                    Millions of products to search for
                </div>

                <SearchBar 
                    searchBarClassName={"products-searchEngine"}
                    searchBarInputClassName={"products-searchEngine-inputWrapper"}
                    searchBarInputBoxClassName={"products-searchEngine-input"}
                    searchButtonClassName={"products-searchBarButton"}

                    searchBar_urlRedirection={isoCodePath + "/product-search-result" + categoryPath + "?productName="}

                    searchBar_placeholder={"Search Product"}
                    searchBar_ariaLabel={"Search Product"}
                    searchBar_suggestionList={productNameList}
                    searchBar_maxTotalSuggestions={5}
                    searchBar_onChange={props.getProductSuggestions}
                />
            </div>

        </div>
    );
}