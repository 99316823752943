import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { PZLongModal, SearchBar, PZButton } from 'Components';

import { func_validation } from 'Functions';

import 'Scss/Components/storeSearchSelect.scss';



export function StoreSearchSelect(props) {
    const { isModalOpen, isModalLoading, storeList: storeList_props, maxStoreSelection } = props;

    const [initSelectedStores, setInitSelectedStores] = useState([]);
    const [selectedStores, setSelectedStores] = useState([]);
    const [isSearchTermReset, setSearchTermStatus] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    // Update state
    useEffect(() => {
        setSelectedStores(initSelectedStores);
    }, [isModalOpen]);

    // Selected Stores
    function handleRemoveStoreSelection(storeDetails) {
        let newStoreList = func_validation.cloneArray(selectedStores);
        let storeName_toRemove = func_validation.getObjectPropertyValue(storeDetails, "name");

        if (func_validation.isObjectNotEmpty(storeDetails)) {
            // Find matched store
            for (let i = 0; i < selectedStores.length; i++) {
                let storeName_selected = func_validation.getObjectPropertyValue(selectedStores[i], "name");
                if (func_validation.isStringEqual_caseInsensitive(storeName_toRemove, storeName_selected)) {
                    newStoreList.splice(i, 1);
                }
            }
        }
        if (func_validation.isArrayValid(newStoreList)) setSelectedStores(newStoreList);

    }
    let selectedStoresDisplay = [];
    if (func_validation.isArrayNotEmpty(selectedStores)) {
        selectedStoresDisplay = selectedStores.map((storeDetails, idx) => {
            let storeLogo = func_validation.getObjectPropertyValueNotNullUndefined(storeDetails, "image", "");
    
            return (
                <div key={idx} className={"storeSearchSelect-storesDisplay-storeLogoOuterWrapper text-center"}>
                    <div className={"storeSearchSelect-storesDisplay-storeLogoWrapper"}
                        onClick={() => { handleRemoveStoreSelection(storeDetails); }}
                    >
                        <img
                            className={"storeSearchSelect-storesDisplay-storeLogo"}
                            src={storeLogo}
                            alt={"Store Logo"}
                        />
                        <FontAwesomeIcon className={"storeSearchSelect-storesDisplay-closeIcon"} icon={faTimesCircle} />
                    </div>
                </div>
            )
        });
    }

    // Store List Display
    function handleStoreSelection(storeDetails) {
        let newStoreList = func_validation.cloneArray(selectedStores);
        let storeName_toRemove = func_validation.getObjectPropertyValue(storeDetails, "name");

        if (func_validation.isObjectNotEmpty(storeDetails)) {
            // Check if any duplicate
            for (let i = 0; i < selectedStores.length; i++) {
                let storeName_selected = func_validation.getObjectPropertyValue(selectedStores[i], "name");

                if (func_validation.isStringEqual_caseInsensitive(storeName_toRemove, storeName_selected)) return;
            }
            newStoreList.push(storeDetails);
        }

        if (func_validation.isArrayNotEmpty(newStoreList) &&
            (!maxStoreSelection || maxStoreSelection > newStoreList.length - 1)) 
            setSelectedStores(newStoreList);
        
    }

    /* Filter list by search term */

    let storeListDisplay = storeList_props;
    if (func_validation.isStringTrimNotEmpty(searchTerm)) {
        storeListDisplay = [];

        // Find exact match
        for (let i = 0; i < storeList_props.length; i++) {
            let storeName = func_validation.getObjectPropertyValueNotNullUndefined(storeList_props[i], "name", "");
    
            let isMatchedFound = false;
            for (let j = 0; j < selectedStores.length; j++) {
                let storeName_selected = func_validation.getObjectPropertyValue(selectedStores[j], "name");
    
                if (func_validation.isStringEqual_caseInsensitive(storeName, storeName_selected)) {
                    isMatchedFound = true;
                    break;
                }
            }
    
            if (func_validation.isStringEqual_caseInsensitive(storeName, searchTerm) && !isMatchedFound) {
                storeListDisplay.push(storeList_props[i]);
                break;
            }
        }
        // Find any store that contains search term
        for (let i = 0; i < storeList_props.length; i++) {
            let storeName = func_validation.getObjectPropertyValueNotNullUndefined(storeList_props[i], "name", "");
    
            let isMatchedFound = false;
            for (let j = 0; j < selectedStores.length; j++) {
                let storeName_selected = func_validation.getObjectPropertyValue(selectedStores[j], "name");
    
                if (func_validation.isStringEqual_caseInsensitive(storeName, storeName_selected)) {
                    isMatchedFound = true;
                    break;
                }
            }
    
            if (func_validation.isStringIncluded_caseIncensitive(storeName, searchTerm) && !isMatchedFound) 
                storeListDisplay.push(storeList_props[i]);
        }
    }

    /* Filter list by selected stores */

    if (func_validation.isArrayNotEmpty(storeListDisplay)) {
        storeListDisplay = storeListDisplay.map((storeDetails, idx) => {
            let storeLogo = func_validation.getObjectPropertyValueNotNullUndefined(storeDetails, "image", "");
            
            // Dont display selected stores
            let storeName = func_validation.getObjectPropertyValueNotNullUndefined(storeDetails, "name", "");
            for (let j = 0; j < selectedStores.length; j++) {
                let storeName_selected = func_validation.getObjectPropertyValue(selectedStores[j], "name");

                if (func_validation.isStringEqual_caseInsensitive(storeName, storeName_selected)) return "";
            }
    
            return (
                <div key={idx} className={"storeSearchSelect-storesDisplay-storeLogoWrapper text-center"}>
                    <img
                        className={"storeSearchSelect-storesDisplay-storeLogo"}
                        src={storeLogo}
                        alt={"Store Logo"}

                        onClick={() => { handleStoreSelection(storeDetails); }}
                    />
                </div>
            )
        });
    }

    function handleOnConfirm() {
        if (func_validation.isFunction(props.onConfirmStoreSelections) &&
            func_validation.isArrayNotEmpty(selectedStores)) {

                setSearchTermStatus(true);
                setInitSelectedStores(selectedStores);
                props.onConfirmStoreSelections(selectedStores);
            }
    }
    
    return (
        <PZLongModal
            className={"storeSearchSelect"}
            isModalOpen={isModalOpen}
            pzLongModal_onModalVisibilityChange={props.setModalVisibility}
            pzLongModal_header={"Store Selections"}
            pzLongModal_isLoading={isModalLoading}

            pzLongModal_footer={(
                <PZButton 
                    pzButton_isSecondary
                    pzButton_onClick={handleOnConfirm}
                >
                    Confirm
                </PZButton>
            )}
        >
            <React.Fragment>
                <SearchBar
                    searchBar_onChange={setSearchTerm}
                    searchBar_placeholder={"Filter Stores"}
                    isSearchButtonHidden isUrlRedirectionNotRequired
                    isSearchTermReset={isSearchTermReset}
                />

                {/* Stores Display */}
                <div className={"storeSearchSelect-storesDisplay"}>
                    {/* Selected Stores */}
                    <div className={"storeSearchSelect-storesDisplay-selectedStores"}>
                        <div className={"storeSearchSelect-storesDisplay-title text-center"}>Selected Stores: {(func_validation.isNumberValid(maxStoreSelection)? "(Max. " + maxStoreSelection + ")":"")}</div>
        
                        <div className={"d-flex align-items-center justify-content-center flex-wrap"}>
                            {selectedStoresDisplay}
                        </div>
                    </div>

                    {/* Available Stores */}
                    <div className={"storeSearchSelect-storesDisplay-availableStores d-flex align-items-center justify-content-center flex-wrap "}>
                        {storeListDisplay}
                    </div>
                </div>
            </React.Fragment>
        </PZLongModal>
    )
}