import React from 'react';
import { useParams } from "react-router-dom";

import { faStore, faTags, faBoxOpen } from '@fortawesome/free-solid-svg-icons';

import SidebarMenu from './SidebarMenu';

import { func_validation, func_url, func_utilities } from '../../Functions';

import 'Scss/Modules/sidebar.scss';

import appLogo from 'Images/app_logo.png';

export default function SidebarPresentation(props) {
    const { pzSidebar_className, isSidebarVisible } = props;

    let { isoCode, category } = useParams();

    // Props validations
    let isoCodePath = "";
    if (func_validation.isStringTrimNotEmpty(isoCode)) isoCodePath = isoCode + "/";
    let categoryPath = "";
    if (func_validation.isStringTrimNotEmpty(category)) categoryPath = category + "/";

    function handleRedirection() {
        func_url.pushHistory(props, "/" + isoCodePath + "stores/" + categoryPath);
        if (props.toggleSidebarVisibility) props.toggleSidebarVisibility();
    }

    if (!func_utilities.isMobile()) return "";

    let sidebarMenuArray = [
        {
            icon: faStore,
            name: "Store",
            path: "/" + isoCodePath + "stores/" + categoryPath,
        },
        {
            icon: faTags,
            name: "Coupon",
            path: "/" + isoCodePath + "coupons/" + categoryPath,
        },
        // {
        //     icon: faBoxOpen,
        //     name: "Products",
        //     path: "/" + isoCodePath + "products/" + categoryPath,
        // },
    ];

    function handleVisibility() {
        window.document.body.style.overflow = "initial";
        if (props.toggleSidebarVisibility) props.toggleSidebarVisibility();
    }
    let sidebarMenu = sidebarMenuArray.map((itemDetail, idx) => {
        let icon = func_validation.getObjectPropertyValue(itemDetail, "icon");
        let name = func_validation.getObjectPropertyValue(itemDetail, "name");
        let path = func_validation.getObjectPropertyValue(itemDetail, "path");

        return (
            <SidebarMenu {...props} key={idx}
                sidebarMenu_icon={icon}
                sidebarMenu_itemName={name}
                sidebarMenu_path={path}

                handleSidebarVisibility={handleVisibility}
            />
        );
    });

    return (
        <div className={"sidebar " + 
                        (pzSidebar_className? pzSidebar_className + " ":"")}>
            {/* Background */}
            {isSidebarVisible?(
                <div className={"sidebar-background"} onClick={handleVisibility}/>
            ):""}

            {/* Menu */}
            <div className={"sidebar-menu " +
                            (isSidebarVisible? "sidebar-menu__open ": "sidebar-menu__close ")}>

                {/* Header */}
                <div className={"sidebar-menuHeader d-flex align-items-center justify-content-center"}>
                    <img
                        src={appLogo}
                        alt={"App Logo"}
                        onClick={handleRedirection}
                    />
                </div>

                {/* Body */}
                <div className={"sidebar-menuBody"}>
                    {sidebarMenu}
                </div>

            </div>
        </div>
    );
}