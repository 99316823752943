import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import loggerMiddleware from './Dataflow/Reducers/logger'

import App from './App';

import rootReducer from './Dataflow/Reducers';

import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css';


const middlewareEnhancer = applyMiddleware(loggerMiddleware, thunkMiddleware);

const composedEnhancers = compose(
    middlewareEnhancer,
  )

const store = createStore(rootReducer, undefined, composedEnhancers);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
