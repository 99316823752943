import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import SitemapGenerator from "./Components/SitemapGenerator";

import TopBarPrompt from './Modules/TopBarPrompt/TopBarPrompt';
import Notification from './Modules/Notification/Notification';
import NavigationBar from './Modules/NavigationBar/NavigationBar';
import Stores from './Modules/Stores/Stores';
import Coupons from './Modules/Coupons/Coupons';
import Products from './Modules/Products/Products';
import ProductSearchResult from './Modules/ProductSearchResult/ProductSearchResult';
import Sidebar from './Modules/Sidebar/Sidebar';

import './Scss/App/app.scss';
import './Scss/Utilities/utilities.scss';
import { getCookie, getCookieValue } from 'Functions';

function AppPresentation(props) {
	const { isTopBarPromptVisible } = props;

	// return (<SitemapGenerator/>);
	return (
		<div className={"app"}>
			<Router history={createBrowserHistory()} >
				{/* Navigation Bar */}
				<Route path={["/:isoCode/:type/:category", "/"]} component={NavigationBar}/>

				{/* Sidebar */}
				<Route path={["/:isoCode/:type/:category", "/"]} component={Sidebar}/>

				{/* Top Bar Prompt */}
				{/* <Route path="/" component={TopBarPrompt}/> */}

				{/* Notification */}
				<Route path="/" component={Notification}/>

				{/* Body */}
				<div id={"powzieAppBody"} className={"app-body ml-auto mr-auto " + 
								(isTopBarPromptVisible? "app-body_adsMarginTop ":"")}>
					<Switch>
						{/* Specific Country */}
						<Route path="/:isoCode/stores/:category/:advertisementId" component={Stores} />
						<Route path="/:isoCode/stores/:category" component={Stores} />
						<Route path="/:isoCode/stores" component={Stores} />
						<Route path="/:isoCode/coupons" component={Coupons} />
						{/* <Route path="/:isoCode/products/:category" component={Products} />
						<Route path="/:isoCode/products" component={Products} />
						<Route path="/:isoCode/product-search-result/:category" component={ProductSearchResult} />
						<Route path="/:isoCode/product-search-result" component={ProductSearchResult} /> */}

						{/* Worldwide */}
						<Route path="/stores/:category/:storeId/:advertisementNo" component={Stores} />
						<Route path="/stores/:category/:advertisementId" component={Stores} />
						<Route path="/stores/:category" component={Stores} />
						<Route path="/stores" component={Stores} />
						<Route path="/coupons" component={Coupons} />
						{/* <Route path="/products/:category" component={Products} />
						<Route path="/products/" component={Products} />
						<Route path="/product-search-result/:category" component={ProductSearchResult} />
						<Route path="/product-search-result" component={ProductSearchResult} /> */}

						<Redirect to={(getCookieValue("country")? "/" + getCookieValue("country"):"") + "/stores" + (getCookieValue("category")? "/" + getCookieValue("category"):"")} />
					</Switch>
				</div>

				{/* Footer */}
				<Switch>

				</Switch>
			</Router>
		</div>
	);
}


function mapStateToProps(state) {
    const { isTopBarPromptVisible } = state.advertisementReducers;

	return {
        isTopBarPromptVisible
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const App = connect(mapStateToProps, mapDispatchToProps)(AppPresentation);



export default App;