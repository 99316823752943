import { connect } from 'react-redux';

import { notificationActions } from 'Dataflow/Actions';

import NotificationPresentation from './NotificationPresentation';

function mapStateToProps(state) {
    const { notificationDetail } = state.notificationReducers;

	return {
        notificationDetail,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getNotificationDetail: () => { dispatch(notificationActions.getNotificationDetail()); },
    };
}

const Notification = connect(mapStateToProps, mapDispatchToProps)(NotificationPresentation);

export default Notification;