import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { func_validation, func_url } from 'Functions';

import 'Scss/Modules/sidebarMenu.scss';

export default function SidebarMenu(props) {
    const { sidebarMenu_className,
            sidebarMenu_icon, sidebarMenu_iconClassName,
            sidebarMenu_itemName, sidebarMenu_itemNameClassName } = props;

    function handleRedirection() {
        const { sidebarMenu_path } = props;

        let sidebarMenu_path_formatted = "/";
        if (func_validation.isStringTrimNotEmpty(sidebarMenu_path)) sidebarMenu_path_formatted = sidebarMenu_path;

        func_url.pushHistory(props, sidebarMenu_path_formatted);

        // Hide Sidebar
        if (props.handleSidebarVisibility) props.handleSidebarVisibility();
    }

    // No item name
    if (!func_validation.isStringTrimNotEmpty(sidebarMenu_itemName)) return "";

    return(
        <div className={"sidebarMenu " + 
                        (sidebarMenu_className? sidebarMenu_className + " ":"")}>

            {/* Icon */}
            {(func_validation.isObjectNotEmpty(sidebarMenu_icon))? (
                <div className={"sidebarMenu-icon " +
                                (sidebarMenu_iconClassName? sidebarMenu_iconClassName + " ":"")}
                    onClick={handleRedirection}                
                >
                    <FontAwesomeIcon icon={sidebarMenu_icon} />
                </div>
            ):""}

            {/* Menu Item */}
            <span className={"sidebarMenu-itemName " +
                            (sidebarMenu_itemNameClassName? sidebarMenu_itemNameClassName + " ":"")} 
                onClick={handleRedirection}                
            >
                {sidebarMenu_itemName}
            </span>

        </div>
    );
}