import { connect } from 'react-redux';

import { productsActions } from 'Dataflow/Actions';

import SearchResultPresentation from './SearchResultPresentation';

function mapStateToProps(state) {
    const { getProductSearchList_requesting, productSearchList,
            getStoreList_requesting, storeList } = state.productsReducers;

	return {
        getProductSearchList_requesting,
        productSearchList,

        getStoreList_requesting, 
        storeList
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getStoreList: (isoCode, category) => { dispatch(productsActions.getStoreList(isoCode, category)); },
        getProductSearchList: (searchOptions, prependProductList) => { dispatch(productsActions.getProductSearchList(searchOptions, prependProductList)); }
    };
}

const SearchResult = connect(mapStateToProps, mapDispatchToProps)(SearchResultPresentation);

export default SearchResult;