import React from 'react';
import { useParams, useLocation } from "react-router-dom";

import { GeneralLayout } from 'Components';
import SearchResult from './SearchResult/SearchResult';

import { func_validation }  from 'Functions';

import 'Scss/Modules/productSearchResult.scss';



function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ProductSearchResultPresentation(props) {
    const { countryDetails, 
            getCountryList_requesting, countryList,
            history } = props;

    let { isoCode, category } = useParams();
    isoCode = func_validation.isStringTrimNotEmpty(isoCode)? isoCode: "WW";
    if (func_validation.isNullUndefined(category)) category = "All";
    let query = useQuery();
    let productName = query.get("productName");

    return (
        <GeneralLayout
            pageHeaderText={"Products"}

            countryList={countryList}
            countryDetails={countryDetails}
            getCountryList_requesting={getCountryList_requesting}
            getCountryList={props.getCountryList}
            
            getCountryDetails={props.getCountryDetails}

            isoCode={isoCode}
            category={category}

            mainUrlPathname={"/product-search-result/"}
            history={history}

            isAdvertisementHidden
        >
            <div className={"productSearchResult"}>
                {/* Result */}
                <div className={"productSearchResult-body d-flex justify-content-center flex-column"}>
                    <SearchResult/>
                </div>

            </div>
        </GeneralLayout>
    );
}