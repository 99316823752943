import { countryConstants } from '../Constants';
import { func_validation, func_url } from '../../Functions';

export const countryActions = {
    getCountryDetails,
    getCountryList,
}

function getCountryDetails(isoCode) {
    return dispatch => {
        dispatch(request());

        const axios = require('../../../node_modules/axios');

        let param = "";
        if (func_validation.isStringTrimNotEmpty(isoCode)) param += "isoCode=" + isoCode;

        axios.get('/api/getCountryDetails.php?' + param)
			.then(function (details) {
				// handle success
				dispatch(success(details));
			})
			.catch(function (error) {
				// handle error
				dispatch(failure(error));
			})
			.finally(function () {
				// always executed
			});
    }

    function request(details) { return { type: countryConstants.GET_COUNTRY_DETAILS_REQUESTING, details }; }
    function success(details) { return { type: countryConstants.GET_COUNTRY_DETAILS_SUCCESS, details }; }
    function failure(error) { return { type: countryConstants.GET_COUNTRY_DETAILS_FAILURE, error }; }
}

function getCountryList() {
    return dispatch => {
        dispatch(request());

        const axios = require('../../../node_modules/axios');

        axios.get('/api/getCountryList.php?')
			.then(function (details) {
				// handle success
				dispatch(success(details));
			})
			.catch(function (error) {
				// handle error
				dispatch(failure(error));
			})
			.finally(function () {
				// always executed
			});
    }

    function request(details) { return { type: countryConstants.GET_COUNTRY_LIST_REQUESTING, details }; }
    function success(details) { return { type: countryConstants.GET_COUNTRY_LIST_SUCCESS, details }; }
    function failure(error) { return { type: countryConstants.GET_COUNTRY_LIST_FAILURE, error }; }
}