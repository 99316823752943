import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";

import { SearchBar, PZHamburger } from 'Components';

import { func_url } from 'Functions/func_url';
import { func_validation } from 'Functions';

import appLogo from 'Images/app_logo.png';

import 'Scss/Modules/navigationBar.scss';



export default function NavigationBarPresentation(props) {
    const { storeNameList, productNameList } = props;

    const [isBackgroundDarken, setBackgroundDarken] = useState(false);
    const [dropdownValue, setDropdownValue] = useState("");
    const { isoCode, category } = useParams();
    let { pathname } = useLocation();
    pathname = func_validation.isStringTrimNotEmpty(pathname)? pathname:"";

    function handleRedirection(extendPath) {
        let extendPath_formatted = "";
        if (func_validation.isStringTrimNotEmpty(extendPath)) extendPath_formatted = "/" + extendPath;

        let isoCodePath = "";
        if (func_validation.isStringTrimNotEmpty(isoCode)) isoCodePath = "/" + isoCode;

        let categoryPath = "";
        if (func_validation.isStringTrimNotEmpty(category)) categoryPath = "/" + category;

        let url = isoCodePath + extendPath_formatted + categoryPath;
        if (url == "") url = "/";
        
        func_url.pushHistory(props, url);
    }  

    // Dropdown
    let dropdownOptions = [
        {
            label: "Stores",
            value: "stores"
        },
        {
            label: "Products",
            value: "products"
        }
    ];
    function handleDropdownOnChange(value) {
        setDropdownValue(value);
    }
    let isSearchBarVisible = !pathname.includes("products");
    let searchBarPlaceholder = "", searchBarRedirectionUrl = "";
    let searchBarSuggestionFunc = () => {};
    let suggestionList = [];
    if (func_validation.isStringEqual_caseInsensitive(dropdownValue, "products")) {

        searchBarPlaceholder = "Search Product"; 

        let isoCodePath = "";
        if (func_validation.isStringTrimNotEmpty(isoCode)) isoCodePath = "/" + isoCode;

        let categoryPath = "";
        if (func_validation.isStringTrimNotEmpty(category)) categoryPath = "/" + category;

        searchBarRedirectionUrl = isoCodePath + "/product-search-result" + categoryPath + "?productName=";
        
        searchBarSuggestionFunc = props.getProductSuggestions;
        suggestionList = productNameList;
    }
    else {
        searchBarPlaceholder = "Search Store"; 
        searchBarRedirectionUrl = "/stores?storeName=";
        if (isoCode) searchBarRedirectionUrl = "/" + isoCode + searchBarRedirectionUrl;
        searchBarSuggestionFunc = props.getStoreNameList;
        suggestionList = storeNameList;
    }
    useEffect(() => {
        if (pathname.includes("product-search-result")) {
            setDropdownValue("products");
        }
        else {
            setDropdownValue("stores");
        }
    }, []);

    return (
        <div className={"navigationBar " + 
                        (isBackgroundDarken? "darkenBakcground ":"")}>
            <div className={"navigationBarWrapper d-flex align-items-center justify-content-center "}>
                {/* Hamburger */}
                <PZHamburger
                    pzHamburger_className={"navigationBar-hamburger d-md-none"}

                    toggleSidebarVisibility={props.toggleSidebarVisibility}
                />

                {/* App Logo */}
                <img className="navigationBar-appLogo d-none d-md-block"
                    src={appLogo}
                    alt={"App logo"}
                    onClick={() => { handleRedirection(""); }}
                />

                {/* Search Bar */}
                <SearchBar {...props}
                    searchBar_placeholder={searchBarPlaceholder}
                    searchBarClassName={"navigationBar-searchBar " + 
                                        (!isSearchBarVisible? "invisible ":"")}
                    searchBarInputBoxClassName={"navigationBar-searchBarInput searchBar-searchInput_noBorderRadius"}
                    searchBar_urlRedirection={searchBarRedirectionUrl}

                    // isDropdownVisible
                    // searchBarDropdownClassName={"navigationBar-dropdown"}
                    // searchBar_dropdownOptions={dropdownOptions}
                    // searchBar_dropdownValue={dropdownValue}
                    // dropdownOnChange={handleDropdownOnChange}

                    searchBar_suggestionList={suggestionList}
                    searchBar_onChange={searchBarSuggestionFunc}

                    searchBar_toggleDarkenBackground={() => { setBackgroundDarken(!isBackgroundDarken); }}
                    searchBar_maxTotalSuggestions={5}

                    isDarkBackgroundRequired
                />

                {/* Links */}
                <div className={"navigationBar-links d-none d-md-block "}>
                    <span className={"navigationBar-storeLink"}
                        onClick={() => { handleRedirection("stores"); }}
                    >
                        Stores
                    </span>

                    <span className={"navigationBar-couponLink"} 
                        onClick={() => { handleRedirection("coupons"); }}
                    >
                        Coupons
                    </span>

                    {/* <span className={"navigationBar-productLink"} 
                        onClick={() => { handleRedirection("products"); }}
                    >
                        Products
                    </span> */}
                </div>
            </div>
        </div>
    );
}