import { notificationConstants } from '../Constants';

export function notificationReducers(state = {}, action) {
    switch (action.type) {
        
        // Get Notification Detail

        case notificationConstants.GET_NOTIFICATION_DETAILS_REQUESTING:
            return {
                ...state,
                getNotificationDetail_requesting: true, getNotificationDetail_status: false,  
            }
        case notificationConstants.GET_NOTIFICATION_DETAILS_SUCCESS:
            return {
                ...state,
                getNotificationDetail_requesting: false, getNotificationDetail_status: true,
                notificationDetail: action.details.data,
            }
        case notificationConstants.GET_NOTIFICATION_DETAILS_FAILURE:
            return {
                ...state,
                getNotificationDetail_requesting: false, getNotificationDetail_status: false, 
            }
            
        default:
            return state;
    }
}