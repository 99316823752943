import { connect } from 'react-redux';

import { couponsActions, storesActions } from 'Dataflow/Actions';

import CouponModalPresentation from './CouponModalPresentation';

function mapStateToProps(state) {
    const { getCoupons_requesting, getCoupons_status, 
            couponList, couponList_meta } = state.couponsReducers;

    const { getStoreDetails_requesting, getStoreDetails_status, storeDetails } = state.storesReducers;

	return {
        getCoupons_requesting, 
        getCoupons_status,
        couponList, 
        couponList_meta,

        getStoreDetails_requesting, 
        getStoreDetails_status,
        storeDetails,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCoupons: (idx, storeAffiliateID, offset, affiliateProgram, pageSize) => { dispatch(couponsActions.getCoupons(idx, storeAffiliateID, offset,affiliateProgram, pageSize)); },
        getStoreDetails: (storeName) => { dispatch(storesActions.getStoreDetails(storeName)); },
    };
}

const CouponModal = connect(mapStateToProps, mapDispatchToProps)(CouponModalPresentation);

export default CouponModal;