import Cookies from "universal-cookie";

import { isStringTrimNotEmpty, isNotNullUndefined } from ".";
import { isArrayValid, isObject, isObjectValid } from "./func_validations";

const cookies = new Cookies();

export const func_cookie = {
    getCookie,
    getCookieAll,
    getCookieValue,

    setCookie,

    removeCookie,
    removeAllCookies,

    isCookieValid,
    isUserLoggedIn
}

export function getCookie() {
    return cookies;
}

export function getCookieAll() {
    return cookies.getAll();
}

export function getCookieValue(cookieName, isValueParsed) {
    if (isStringTrimNotEmpty(cookieName)) {
        let cookieValue = cookies.get(cookieName, { doNotParse: !isValueParsed });
        if (isNotNullUndefined(cookieValue)) return cookieValue;
    }
    return undefined;
}

export function setCookie(cookieName, cookieValue, cookieOptions) {
    if (isStringTrimNotEmpty(cookieName) && 
        isNotNullUndefined(cookieValue)) {
        
        let cookieValue_final = cookieValue;
        if (isArrayValid(cookieValue) || isObjectValid(cookieValue)) {
            cookieValue_final = JSON.stringify(cookieValue_final);
        }

        cookies.set(cookieName, cookieValue_final, cookieOptions);
        return true;
    }
    return false;
}

export function removeCookie(cookieName, cookieOptions) {
    if (isStringTrimNotEmpty(cookieName)) {
        cookies.remove(cookieName, cookieOptions);
        return true;
    }
    return false;
}

export function removeAllCookies() {
    let cookies = getCookieAll();
    let isEveryCookieRemoved = true;
    for (const property in cookies) {
        if (isStringTrimNotEmpty(property)) {
            let isRemoved = removeCookie(property, { path: '/' });

            if (!isRemoved) isEveryCookieRemoved = false;
        }
    }
    return isEveryCookieRemoved;
}

export function isCookieValid(cookieName) {
    let value = getCookieValue(cookieName);
    return isNotNullUndefined(value);
}

export function isUserLoggedIn() {
    return (isCookieValid("uid") &&
        isCookieValid("rt") &&
        isCookieValid("idt"))
}