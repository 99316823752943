import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faStar, faTruck } from '@fortawesome/free-solid-svg-icons';

import logo_mini from '../Images/app_logo_mini.svg';

import { func_validation, func_url } from 'Functions';

import 'Scss/Components/productCard.scss';
import { PZButton } from './PZButton';

export function ProductCard(props) {
    const { isBoxShadowRequired, productDetail, 
            totalStores 
        } = props;

    const [isProductDetailVisible, setProductdetailVisibility] = useState(false);

    let image = func_validation.getObjectPropertyValue(productDetail, "image");
    let name = func_validation.getObjectPropertyValue(productDetail, "name");

    let totalRatings = func_validation.getObjectPropertyValue(productDetail, "totalRatings");
    let rating = func_validation.getObjectPropertyValue(productDetail, "productRating");
    let sellerRating = func_validation.getObjectPropertyValue(productDetail, "sellerRating");

    let currency = func_validation.getObjectPropertyValue(productDetail, "currency");
    let price = func_validation.getObjectPropertyValue(productDetail, "currentPrice");
    let prevPrice = func_validation.getObjectPropertyValue(productDetail, "normalPrice");
    let discount = func_validation.getObjectPropertyValue(productDetail, "discountPercentage");
    
    let isFreeShipping = func_validation.getObjectPropertyValue(productDetail, "isFreeShipping");
    
    let storeLogo = func_validation.getObjectPropertyValue(productDetail, "storeLogo");
    
    // Validate Props
    let image_formatted = logo_mini;
    if (func_validation.isStringTrimNotEmpty(image)) image_formatted = image;
    image_formatted = image_formatted.replace("https:", "http:"); // Allow displaying from unsecure sites.
    let name_formatted = "";
    if (func_validation.isStringTrimNotEmpty(name)) name_formatted = name;

    let rating_formatted = "";
    // if (func_validation.isNumberValid(rating) && (rating != 0)) rating_formatted = rating.toPrecision(2);
    let totalRatings_formatted = "";
    // if (func_validation.isNumberValid(totalRatings) && (totalRatings != 0)) totalRatings_formatted = "(" + totalRatings + ")";

    let price_formatted = "";
    let price_extracted = parseFloat(price);
    if (func_validation.isNumberValid(price_extracted) && (price_extracted != 0)) price_formatted = currency + " " + price_extracted;
    else return ""; // No price, no display at all.
    let prevPrice_formatted = "";
    let prevPrice_extracted = parseFloat(prevPrice);
    if (func_validation.isNumberValid(prevPrice) && (prevPrice_extracted > 0.01) &&
        (func_validation.isNumberValid(discount) && (discount != 0))) prevPrice_formatted = currency + " " + prevPrice_extracted;
    let discount_formatted = "";
    if (func_validation.isNumberValid(discount) && (discount != 0)) discount_formatted = discount + "% OFF";

    let isFreeShipping_formatted = "";
    // if (func_validation.isBooleanTrueNotNullUndefined(isFreeShipping)) isFreeShipping_formatted = "FREE Shipping ";

    let totalStores_formatted = "";
    // if (func_validation.isNumberValid(totalStores) && (totalStores > 1)) totalStores_formatted = totalStores + " Store(s)";
    let storeLogo_formatted = "";
    if (func_validation.isStringTrimNotEmpty(storeLogo)) storeLogo_formatted = storeLogo;

    function handleUrlRedirection() {
        let productUrl = func_validation.getObjectPropertyValue(productDetail, "redirectionUrl");

        if (func_validation.isStringTrimNotEmpty(productUrl) && 
            !productUrl.includes("http")) 
            func_url.openUrlInNewTab("http://" + productUrl);
        else if (func_validation.isStringTrimNotEmpty(productUrl))
            func_url.openUrlInNewTab(productUrl);
        
    }
    function handleComparePriceTrigger() {
        if (props.onClick_comparePrice) props.onClick_comparePrice();
    }
    function toggleProductDetailVisibility() {
        setProductdetailVisibility(!isProductDetailVisible);
    }

    return (
        <div className={"productCard "}>
            <div className={"productCardWrapper " +
                            (isBoxShadowRequired? "productCardWrapper_borderShadow ":"") +
                            (isProductDetailVisible? "productCardWrapper_expandHeight ":"")}>
                {/* Product Image */}
                <div className="productCard-imageWrapper text-center"
                    onClick={handleUrlRedirection}
                >
                    <img
                        className="productCard-image"
                        src={image_formatted}
                        alt={"Product Image"}
                    />
                </div>

                {/* Compare prices */}
                {func_validation.isNumberValid(totalStores) &&
                (totalStores> 1)? (
                    <PZButton
                        pzButton_className={"productCard-comparePrice"}
                        pzButton_isSecondary
                        pzButton_onClick={handleComparePriceTrigger}
                    >
                        Compare Price
                    </PZButton>
                ):""}

                {/* Name */}
                <div className={"productCard-name " +
                                (isProductDetailVisible? "":"d-none d-md-block ")}
                    onClick={handleUrlRedirection}
                >
                    {name_formatted}
                </div>
                
                {/* Rating */}
                {func_validation.isStringTrimNotEmpty(rating_formatted) &&
                func_validation.isStringTrimNotEmpty(totalRatings_formatted)? (
                    <div className={"productCard-ratingWrapper align-items-center " +
                                    (isProductDetailVisible? "d-flex ":"d-none d-md-flex ")}
                        onClick={handleUrlRedirection}
                    >
                        <div className={"productCard-rating d-flex align-items-center"}>
                            <span className={"productCard-rating-value"}>{rating_formatted}</span>
                            <FontAwesomeIcon className={"productCard-rating-icon"} icon={faStar} />
                        </div>
                        <div className={"productCard-totalRatings"}>
                            {totalRatings_formatted}
                        </div>
                    </div>
                ):""}

                {/* Price */}
                <div className={"productCard-priceWrapper "}
                    onClick={handleUrlRedirection}
                >
                    <div className="productCard-price">
                        {price_formatted}
                    </div>

                    <div className="productCard-prevPrice">
                        {prevPrice_formatted}
                    </div>

                    <div className="productCard-discount">
                        {discount_formatted}
                    </div>
                </div>

                {/* View Details Button (Mobile) */}
                <div className={"productCard-viewDetails text-center d-md-none"}
                    onClick={toggleProductDetailVisibility}
                >
                    {isProductDetailVisible? "Hide Details":"View Details"}
                </div>

                {/* Free Shipping Label */}
                {func_validation.isStringTrimNotEmpty(isFreeShipping_formatted)? (
                    <div className={"productCard-freeShipping align-items-center " +
                                    (isProductDetailVisible? "d-flex ":"d-none d-md-flex ")}
                        onClick={handleUrlRedirection}
                    >
                        <span className="productCard-freeShipping-label">{isFreeShipping_formatted}</span>
                        <FontAwesomeIcon className={"productCard-freeShipping-icon"} icon={faTruck} />
                    </div>
                ):""}

                {/*  Total Stores */}
                <div className={"productCard-totalStores " +
                                (isProductDetailVisible? "":"d-none d-md-block ")}
                    onClick={handleUrlRedirection}
                >
                    {totalStores_formatted}
                </div>

                {/* Shop Now Button */}
                <div className={"productCard-shopNow d-flex align-items-center justify-content-center "}
                    onClick={handleUrlRedirection}
                >
                    <img className="productCard-shopNow-storeLogo"
                        src={storeLogo_formatted}
                        alt={"Store Logo"}
                    />

                    <FontAwesomeIcon 
                        className={"productCard-shopNow-rightArrow"}
                        icon={faChevronRight}
                    />

                </div>
            </div>
        </div>
    );
}