import { storesConstants } from '../Constants';

export function storesReducers(state = {}, action) {
    switch (action.type) {

        // Get Store Name List

        case storesConstants.GET_STORE_NAME_LIST_REQUESTING:
            return {
                ...state,
                getStoreNameList_requesting: true, getStoreNameList_status: false,
                storeNameList: undefined,
            }
        case storesConstants.GET_STORE_NAME_LIST_SUCCESS:
            return {
                ...state,
                getStoreNameList_requesting: false, getStoreNameList_status: true,
                storeNameList: action.details.data
            }
        case storesConstants.GET_STORE_NAME_LIST_FAILURE:
            return {
                ...state,
                getStoreNameList_requesting: false, getStoreNameList_status: false, 
                storeNameList: undefined,
            }

        // Get Stores

        case storesConstants.GET_STORES_REQUESTING:
            return {
                ...state,
                getStores_requesting: true, getStores_status: false,
                storeList_metadata: undefined,
            }
        case storesConstants.GET_STORES_SUCCESS:
            return {
                ...state,
                getStores_requesting: false, getStores_status: true,
                storeList: action.details.data.result,
                storeList_metadata: action.details.data.metadata
            }
        case storesConstants.GET_STORES_FAILURE:
            return {
                ...state,
                getStores_requesting: false, getStores_status: false, 
                storeList: undefined,
                storeList_metadata: undefined,
            }

        // Get Store Details

        case storesConstants.GET_STORE_DETAILS_REQUESTING:
            return {
                ...state,
                getStoreDetails_requesting: true, getStoreDetails_status: false,
            }
        case storesConstants.GET_STORE_DETAILS_SUCCESS:
            return {
                ...state,
                getStoreDetails_requesting: false, getStoreDetails_status: true,
                storeDetails: action.details.data
            }
        case storesConstants.GET_STORE_DETAILS_FAILURE:
            return {
                ...state,
                getStoreDetails_requesting: false, getStoreDetails_status: false, 
            }

        default:
            return state;
    }
}