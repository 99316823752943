import React from 'react';

import { Spinner } from 'Components';

import 'Scss/Components/loadMoreButton.scss';
import { func_validation } from 'Functions';



export function LoadMoreButton(props) {
    const { offset, text, isLoading } = props;

    // Props Validation
    let text_formatted = "Load More";
    if (func_validation.isStringTrimNotEmpty(text)) text_formatted = text;

    function handleLoadContent() {
        if (func_validation.isFunction(props.loadContent)) props.loadContent(offset);
    }

    return (
        <div className={"loadMoreButton d-flex justify-content-center"}>
            <div className={"loadMoreButton-loadMoreButton d-flex align-items-center"}
                onClick={handleLoadContent}
            >
                {text_formatted}{isLoading && <Spinner size={0} />}
            </div>
        </div>
    );
}