import { connect } from 'react-redux';

import { productsActions } from 'Dataflow/Actions';

import ProductsPresentation from './ProductsPresentation';

function mapStateToProps(state) {
    const { productNameList } = state.productsReducers;

	return {
        productNameList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getProductSuggestions: (searchTerm, sizeLimit) => { dispatch(productsActions.getProductSuggestions(searchTerm, sizeLimit)); },
    };
}

const Products = connect(mapStateToProps, mapDispatchToProps)(ProductsPresentation);

export default Products;