import React from 'react';

import '../Scss/Components/pzButton.scss';
import { func_validation } from 'Functions';

export function PZButton(props) {
    const { pzId, pzButton_className,
        pzButton_isSecondary } = props;

    function handleOnClick(e) {
        if (props.pzButton_onClick) props.pzButton_onClick();
    }

    let pzId_formatted = func_validation.isStringTrimNotEmpty(pzId)? pzId:"";

    return(
        <div id={pzId_formatted} 
            className={"pzButton text-center " + 
                        (pzButton_className? pzButton_className + " ":"") +
                        (pzButton_isSecondary? "secondaryButton ":"")}
            onClick={handleOnClick}                
        >
            {props.children}
        </div>
    );
}