import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { func_validation } from 'Functions';

import 'Scss/Components/dropdown.scss';
import { InputText } from './InputText';



export function Dropdown(props) {
    const { className, selectedValueWrapperClassName, selectedValueClassName, arrowClassName, 
            options: options_original, 
            selectedValue_default, selectedValueLabel_default,
            placeholder: placeholder_props, isFormSubmissionAttempted,
            searchInputClassName, searchInputPlaceholder, isSearchInputRequired,
            isScrollBarVisible, isOnlyForDisplay } = props;

    // Props Validation
    let searchInputPlaceholder_validated = func_validation.isStringTrimNotEmpty(searchInputPlaceholder)? searchInputPlaceholder:"";
    let options_original_validated = func_validation.isArrayValid(options_original)? options_original:[];

    // States
    
    let selectedValue_init = func_validation.isStringTrimNotEmpty(selectedValue_default)? selectedValue_default:"";
    const [selectedValue, setSelectedValue] = useState(selectedValue_init);
    const [options, setOptions] = useState(options_original_validated);

    useEffect(() => {
        if (func_validation.isArrayValid(options_original))
            setOptions(options_original);
    }, [options_original]);

    function getLabelFromOptions(value) {
        let arraySize = func_validation.getArrayLength(options_original, 0);
        for(let i = 0; i < arraySize; i++) {
            let optionValue = func_validation.getObjectPropertyValue(options_original[i], "value");
            if (value === optionValue) {
                let label = func_validation.getObjectPropertyValue(options_original[i], "label");
                return label;
            }
        }
        return "";
    }
    let selectedValueLabel_init = func_validation.isStringTrimNotEmpty(selectedValueLabel_default)? selectedValueLabel_default:
        (func_validation.isNotNullUndefined(selectedValue_init)? 
        getLabelFromOptions(selectedValue_init):"");
    const [selectedValueLabel, setSelectedValueLabel] = useState(selectedValueLabel_init);
    const [isOptionListVisible, setOptionListVisibility] = useState("");

    // Update States
    function updateState(label, value) {
        setSelectedValueLabel(label);
        setSelectedValue(value);
        toggleOptionsVisibility(false);
        
        if (props.onChange) props.onChange(value);
    }
    function updateStateFromParent() {
        if (func_validation.isStringTrimNotEmpty(selectedValueLabel_default)) {
            setSelectedValueLabel(selectedValueLabel_default);
        }
        else if (func_validation.isNotNullUndefined(selectedValue_default)) {
            setSelectedValueLabel(getLabelFromOptions(selectedValue_default));
            setSelectedValue(selectedValue_default);
        }
    }
    useEffect(() => {
        updateStateFromParent();
    }, [options_original, selectedValue_default, selectedValueLabel_default])

    // Search Input
    function handleSearchInput_onChange(searchTerm) {
        let newOptions = [];
        options_original_validated.map((itemDetails) => {
            let label = func_validation.getObjectPropertyValue(itemDetails, "label");
            let label_formatted = label + "";
            label_formatted = label_formatted.toLowerCase();
            let searchTerm_formatted = searchTerm.toLowerCase();
            if (label_formatted.substring(0, searchTerm.length) == searchTerm_formatted) newOptions.push(itemDetails);
        });
        setOptions(newOptions);
    }

    // Placeholder
    let placeholder = func_validation.isStringTrimNotEmpty(placeholder_props)? placeholder_props: "Select...";
    let isPlaceholderVisible = func_validation.isStringTrimEmpty(selectedValueLabel);

    // Option List
    function toggleOptionsVisibility(isVisible) {
        let isVisible_formatted = !isOptionListVisible;
        if (func_validation.isNotNullUndefined(isVisible)) isVisible_formatted = isVisible;

        setOptionListVisibility(isVisible_formatted);

        if (func_validation.isFunction(props.handleOptionsVisibility))
            props.handleOptionsVisibility(isVisible);
    }
    let optionArray = [];
    if (func_validation.isArrayNotEmpty(options)) {
        optionArray = options.map((details, idx) => {
            let label = func_validation.getObjectPropertyValue(details, "label","");
            let value = func_validation.getObjectPropertyValue(details, "value","");
    
            return (
                <div key={idx} className={"dropdown-optionList-option d-flex align-items-center"}
                    onClick={() => { updateState(label, value); }}
                >
                    {label}
                </div>
            );
        });
    }

    let isErrorPresent = isFormSubmissionAttempted && !selectedValueLabel;

    return (
        <div className={"dropdown d-flex align-items-center " + 
                        (className? className + " ":"") +
                        (isErrorPresent? "dropdown_errorBorder ":"")}
            onClick={() => { if (!isSearchInputRequired) toggleOptionsVisibility(); }}
            tabIndex={"-1"} onBlur={() => { if (!isSearchInputRequired) toggleOptionsVisibility(false); }}
        >
            {/* Selected Value Display */}
            <div className={"dropdown-selectedValueWrapper " +
                            (selectedValueWrapperClassName? selectedValueWrapperClassName + " ":"")}>
                <div className={(isPlaceholderVisible? "dropdown-placeholder ":"dropdown-selectedValue ") +
                                (selectedValueClassName? selectedValueClassName + " ":"")}
                >
                    {isSearchInputRequired? (
                        <InputText
                            inputTextInputBox_className={"dropdown-searchInput " + 
                                (searchInputClassName? searchInputClassName + " ":"")}
                            inputText_placeholder={searchInputPlaceholder_validated}
                            defaultInputValue={getLabelFromOptions(selectedValue)}
                            inputText_onChange={handleSearchInput_onChange}
                            onClick={() => { toggleOptionsVisibility(true); }}
                            onFocus={() => { setSelectedValue(""); }}
                        />
                    ):(
                        <span>{isPlaceholderVisible? placeholder: selectedValueLabel}</span>
                    )}
                </div>

                {/* Arrow */}
                <div className={"dropdown-arrow " +
                                (arrowClassName? arrowClassName + " ":"")}
                    onClick={() => { toggleOptionsVisibility(); }}
                >
                    <FontAwesomeIcon className={"dropdown-arrow-icon " + 
                                                (isOptionListVisible && (!isOnlyForDisplay)? "dropdown-icon_rotate ":"")} icon={faChevronDown}/>
                </div>
            </div>

            {/* Option List */}
            {(!isOnlyForDisplay) &&
            isOptionListVisible && 
            func_validation.isArrayNotEmpty(optionArray)? (
                
                <div className={"dropdown-optionList " +
                                (isScrollBarVisible? "dropdown-optionList_scrollBar ":"")}
                >
                    {optionArray}
                </div>
            ):""}

        </div> 
    );
}