import React from 'react';

import { PZTooltip } from 'Components';

import logo_mini from '../Images/app_logo_mini.svg';

import '../Scss/Components/storeCard.scss';
import { func_url, func_validation } from '../Functions';



class StoreCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }

        this.__handleRedirection = this.__handleRedirection.bind(this);
    }

    __handleRedirection() {
        const { storeCard_storeUrl, isOpenUrlInNewTab } = this.props;

        if (func_validation.isStringTrimNotEmpty(storeCard_storeUrl)) {
            if (isOpenUrlInNewTab) {
                func_url.openUrlInNewTab(storeCard_storeUrl);
            }
            else func_url.openUrlInCurrentTab(storeCard_storeUrl);
        }
    }

    render() {
        const { storeCard_className,
                storeCard_imageSrc, storeCard_imageAlt,
                storeCard_name, isStoreCardNameClickable,
                storeCard_description, isOnHoverBorderShadowHidden,
                tooltipClassName, isTooltipVisible, tooltipDescription,
                isTooltipPosition_top, isTooltipPosition_right, isTooltipPosition_bottom, isTooltipPosition_left } = this.props;

        let storeCard_imageSrc_formatted = logo_mini;
        if (func_validation.isStringTrimNotEmpty(storeCard_imageSrc)) storeCard_imageSrc_formatted = storeCard_imageSrc;

        let storeCard_imageAlt_formatted = "Store Logo";
        if (func_validation.isStringTrimNotEmpty(storeCard_imageAlt)) storeCard_imageAlt_formatted = storeCard_imageAlt;

        let storeCard_name_formatted = "Visit Site";
        if (func_validation.isStringTrimNotEmpty(storeCard_name)) storeCard_name_formatted = storeCard_name;

        let storeCard_description_formatted = "View Deals";
        if (func_validation.isNotNullUndefined(storeCard_description)) storeCard_description_formatted = storeCard_description;

        return(
            <div className={"storeCard " + 
                            (storeCard_className? storeCard_className + " ":"")}>
                <div className={"storeCardWrapper d-flex flex-sm-column justify-content-sm-center justify-content-between align-items-center " +
                                (isOnHoverBorderShadowHidden? "": "storeCardWrapper_borderShadow ")}>
                    <div className={"storeCard-imageWrapper text-center d-flex align-items-center justify-content-center"}>
                        <img 
                            className={"storeCard-image"}
                            src={storeCard_imageSrc_formatted}
                            alt={storeCard_imageAlt_formatted}

                            onClick={this.__handleRedirection}
                        />
                    </div>

                    <span className={"storeCard-name d-none d-sm-block text-center " + 
                                    (isStoreCardNameClickable? "clickableText ":"")}
                        onClick={this.__handleRedirection}
                    >
                        {storeCard_name_formatted}
                    </span>

                    <div className={"storeCard-description"}>
                        {storeCard_description_formatted}
                    </div>
                    
                </div>

                {/* Tooltip */}
                {isTooltipVisible? (
                    <PZTooltip 
                        className={"storeCard-tooltip " + 
                                    (tooltipClassName? tooltipClassName + " ":"")}
                        description={tooltipDescription}

                        isAtLocation_top={isTooltipPosition_top}
                        isAtLocation_right={isTooltipPosition_right}
                        isAtLocation_bottom={isTooltipPosition_bottom}
                        isAtLocation_left={isTooltipPosition_left}
                    />
                ):""}
                
            </div>
        );
    }
}

export { StoreCard };