import { couponsConstants } from '../Constants';

export function couponsReducers(state = {}, action) {
    switch (action.type) {
        
        // Get Coupons

        case couponsConstants.GET_COUPONS_REQUESTING:
            return {
                ...state,
                getCoupons_requesting: true, getCoupons_status: false,  
                couponList: undefined, couponList_meta: undefined,
            }
        case couponsConstants.GET_COUPONS_SUCCESS:
            return {
                ...state,
                getCoupons_requesting: false, getCoupons_status: true,
                couponList: action.details.data.results,
                couponList_meta: action.details.data.metadata,
            }
        case couponsConstants.GET_COUPONS_FAILURE:
            return {
                ...state,
                getCoupons_requesting: false, getCoupons_status: false, 
                couponList: undefined, couponList_meta: undefined,
            }
            
        default:
            return state;
    }
}