import { func_validation } from './func_validations';
import { urlPathnameConstants } from '../Dataflow/Constants';

export const func_url = {
    isPathChanged,
    isParamChanged,

    pushHistory,
    getUrlPathname,
    getSecondPath,
    getThirdLastPathName,
    getSecondLastPathName,
    getLastPathName,
    getQueryString,
    getQueryValue,
    
    openUrlInNewTab,
    openUrlInCurrentTab,
}

function isPathChanged(prevProps, props) {
    let pathname_prev = getUrlPathname(prevProps);
    let pathname = getUrlPathname(props);

    return (!func_validation.isStringEqual_caseInsensitive(pathname_prev, pathname));
}

function isParamChanged(prevProps, props) {
    let query_prev = getQueryString(prevProps);
    let query = getQueryString(props);

    return (!func_validation.isStringEqual_caseInsensitive(query_prev, query));
}

function pushHistory(props, url, options) {
    const { history } = props;

    if (func_validation.isObjectNotEmpty(history)) {
        if (func_validation.isStringTrimNotEmpty(url) &&
            func_validation.isObjectNotEmpty(options)) {
            history.push(url, options);
        }
        else if (func_validation.isStringTrimNotEmpty(url)) {
            history.push(url);
        }
    }
}

function getUrlPathname(props) {
    const { history } = props;

    let location = func_validation.getObjectPropertyValue(history, "location");
    let pathname = func_validation.getObjectPropertyValue(location, "pathname");

    return pathname;
}

function getSecondPath(props) {
    let pathName = getUrlPathname(props);
    pathName = pathName.substring(1); // Remove first slash
    let secondSlashPos = pathName.indexOf("/");
    let pathName_firstPathRemoved = pathName.substring(secondSlashPos + 1);
    let endPos = pathName_firstPathRemoved.indexOf("/");
    return pathName_firstPathRemoved.substring(0, endPos);
}

function getThirdLastPathName(props) {
    let pathName = getUrlPathname(props);
    let lastSlashPos = pathName.lastIndexOf("/");
    let pathName_noLast = pathName.substring(0, lastSlashPos)// Remove the last pathname
    let secondLastSlashPos = pathName_noLast.lastIndexOf("/");
    let pathName_noSecondLast = pathName.substring(0, secondLastSlashPos)// Remove the second pathname
    let thirdLastSlashPos = pathName_noSecondLast.lastIndexOf("/");
    let startPos = thirdLastSlashPos + 1;
    return pathName_noSecondLast.substring(startPos);
}

function getSecondLastPathName(props) {
    let pathName = getUrlPathname(props);
    let lastSlashPos = pathName.lastIndexOf("/");
    let pathName_formatted = pathName.substring(0, lastSlashPos)// Remove the last pathname
    let secondLastSlashPos = pathName_formatted.lastIndexOf("/");
    let startPos = secondLastSlashPos + 1;
    return pathName_formatted.substring(startPos);
}

function getLastPathName(props) {
    let pathName = getUrlPathname(props);
    let lastSlashPos = pathName.lastIndexOf("/");
    let startPos = lastSlashPos + 1;
    return pathName.substring(startPos);
}

function getQueryString(props) {
    const { history } = props;

    let location = func_validation.getObjectPropertyValue(history, "location");
    let query = func_validation.getObjectPropertyValue(location, "search");
    if (func_validation.isStringTrimNotEmpty(query)) query = query.substring(1);

    return query;
}

function getQueryValue(queryString, key) {
    if (queryString.indexOf(key)) return "";
    
    let startPos = queryString.indexOf(key) + key.length + 1;
    let endPos = queryString.indexOf("&", startPos);
    if (endPos == -1) endPos = queryString.length;

    return queryString.substring(startPos, endPos);
}

function openUrlInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
}

function openUrlInCurrentTab (url) {
    if (func_validation.isStringTrimNotEmpty(url)) {
        window.open(url,"_self");
    }
}