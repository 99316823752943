import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/free-solid-svg-icons';

import { SearchResultLayout, ProductCard, Dropdown, LoadMoreButton, StoreSearchSelect } from 'Components';

import { func_validation, func_url } from 'Functions';

import 'Scss/Modules/searchResult.scss';
import { PZLongModal } from 'Components/PZLongModal';
import { PZButton } from 'Components/PZButton';
import { Spinner } from 'Components/Spinner';
import { InputText } from 'Components/InputText';


const PAGE_DISPLAY_SIZE = 20;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function SearchResultPresentation(props) {
    const { getProductSearchList_requesting, productSearchList, getStoreList_requesting, storeList: storeList_props } = props;

    const [selectedStores, setSelectedStores] = useState([]);
    const [sortValue, setSortValue] = useState("");
    const [storeList, setStoreList] = useState([]);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(99999999);

    const [isStoreListModalOpen, setStoreListModalVisibility] = useState(false);
    const [productList, setProductList] = useState([]);
    const [pageNo, setPageNo] = useState(0);
    const [currentList_pageSize, setCurrentList_pageSize] = useState(PAGE_DISPLAY_SIZE);
    const [isLoadMoreListActive, setIsLoadMoreListActive] = useState(false);
    const [isLoadMoreHidden, setIsLoadMoreHidden] = useState();

    const [isPriceComparisonModalOpen, setPriceComparisonModalVisibility] = useState(false);
    const [comparisonProductListDisplay, setComparisonProductListDisplay] = useState([]);

    let { isoCode, category } = useParams();
    isoCode = func_validation.isStringTrimNotEmpty(isoCode)? isoCode: "WW";
    let query = useQuery();
    let productName = query.get("productName");

    // Stores List
    function getStoreListArray() {
        if (func_validation.isFunction(props.getStoreList) &&
            func_validation.isStringTrimNotEmpty(isoCode)) 
            props.getStoreList(isoCode, category);
    }
    function setStoreList_validation() {
        if (func_validation.isArrayEmpty(storeList) &&
            func_validation.isArrayNotEmpty(storeList_props)) {
            
            setStoreList(storeList_props);
        }
    }
    useEffect(() => {
        getStoreListArray();
    }, []);
    useEffect(() => {
        setStoreList_validation();
    }, [getStoreList_requesting]);

    // Product List
    function getProductList(args) {
        let selectedStores_formatted = func_validation.getObjectPropertyValue(args, "selectedStores", selectedStores);
        let isoCode_formatted = func_validation.getObjectPropertyValue(args, "isoCode", isoCode);
        let sortType_formatted = func_validation.getObjectPropertyValue(args, "sortType", sortValue);
        let pageNo_formatted = func_validation.getObjectPropertyValue(args, "pageNo", pageNo);
        let minPrice_formatted = func_validation.getObjectPropertyValue(args, "minPrice", minPrice);
        let maxPrice_formatted = func_validation.getObjectPropertyValue(args, "maxPrice", maxPrice);

        let searchOptions = {
            selectedStores: selectedStores_formatted,
            isoCode: isoCode_formatted,
            searchTerm: productName,
            sortType: sortType_formatted,
            pageNo: pageNo_formatted,
            category: category,
            minPrice: minPrice_formatted,
            maxPrice: maxPrice_formatted,
        };

        getProductListArray(searchOptions);
    }
    function getProductListArray(searchOptions) {
        if (props.getProductSearchList &&
            func_validation.isObjectNotEmpty(searchOptions) &&
            func_validation.isArrayValid(productList)) 
            props.getProductSearchList(searchOptions, productList);
    }
    function getMoreProductList() {
        let newPageSize = currentList_pageSize + PAGE_DISPLAY_SIZE;
        if (newPageSize > func_validation.getArrayLength(productList)) {
            let newPageNo = pageNo + 1;

            getProductList({
                pageNo: newPageNo
            });

            setPageNo(newPageNo);
            setIsLoadMoreListActive(true);
        }

        setCurrentList_pageSize(newPageSize);
    }
    function setProductListArray() {
        const { productSearchList } = props;

        if (func_validation.isArrayNotEmpty(productSearchList)) {
            let newList = [];
            // if (isLoadMoreListActive) {
            //     newList = productList.concat(productSearchList);
            //     setIsLoadMoreListActive(false);
            // }
            // else {
            //     newList = func_validation.cloneArray(productSearchList);
            // }
            newList = func_validation.cloneArray(productSearchList);
            
            setProductList(newList);
            setIsLoadMoreHidden(false);
        }
        else if (func_validation.isArrayEmpty(productSearchList) &&
            isLoadMoreListActive) {

            setIsLoadMoreHidden(true);
            setIsLoadMoreListActive(false);
        }
    }
    useEffect(() => {
        getProductList({});
    },[]);
    useEffect(() => {
        setProductListArray();
    },[getProductSearchList_requesting]);

    // Sort
    function handleOnChange_sortBy(newSortValue) {
        setProductList([]);
        setSortValue(newSortValue);

        // Get new product list
        getProductList({ sortType: newSortValue });
    }

    // Action Bar
    function handleStoreSelection_confirm(selectedStores) {
        setStoreListModalVisibility(false);
        setSelectedStores(selectedStores);
        setProductList([]);
        setPageNo(0);
       
        // Get new product list
        getProductList({ selectedStores: selectedStores });
    }
    function handleOnBlur_minPrice(minPrice) {
        let minPrice_parsed = parseFloat(minPrice);
        setMinPrice(minPrice_parsed);
    }
    function handleOnBlur_maxPrice(maxPrice) {
        let maxPrice_parsed = parseFloat(maxPrice);
        setMaxPrice(maxPrice_parsed);
    }
    function filterPrice() {
        setProductList([]);

        // Get new product list
        getProductList({ maxPrice: maxPrice, minPrice: minPrice });
    }
    

    let actionBarOptions = [
        {
            itemClassName: "searchResult-actionBar",
            label: "Stores:",
            display: (
                <React.Fragment>
                    <Dropdown 
                        className={"searchResult-actionBar-storeSelection"}

                        isPlaceholderVisible
                        placeholder={func_validation.isArrayNotEmpty(selectedStores)? 
                            ("(" + func_validation.getArrayLength(selectedStores) + ") Selected"):
                            "Select Store(s)..."}

                        handleOptionsVisibility={() => { setStoreListModalVisibility(true); }}

                        isOnlyForDisplay
                    />
                    <StoreSearchSelect
                        isModalOpen={isStoreListModalOpen}
                        setModalVisibility={setStoreListModalVisibility}

                        storeList={storeList}
                        maxStoreSelection={6}

                        isModalLoading={getStoreList_requesting}

                        onConfirmStoreSelections={handleStoreSelection_confirm}
                    />
                </React.Fragment>
            )
        },
        {
            itemClassName: "searchResult-actionBar",
            label: "Sort By:",
            display: (
                <Dropdown 
                    className={"searchResult-actionBar-sortByDisplay"}
                    options={[
                        {
                            label: "Lowest",
                            value: "Lowest",
                        },
                        {
                            label: "Highest",
                            value: "Highest",
                        },
                        // {
                        //     label: "Rating",
                        //     value: "Rating",
                        // },
                        {
                            label: "Discount",
                            value: "Discount",
                        }
                    ]}
                    selectedValue_default={sortValue}

                    onChange={handleOnChange_sortBy}
                />
            )
        },
        {
            itemClassName: "searchResult-actionBar",
            label: "Price:",
            display: (
                <div className={"searchResult-actionBar-price d-flex align-items-center"}>
                    <div className={"d-flex align-items-center"}>
                        <span>From</span>
                        <InputText
                            inputTextInputBox_className={"searchResult-actionBar-minPrice"}
                            inputText_placeholder={"Min."}
                            inputText_onChange={handleOnBlur_minPrice}
                            isInputOnlyPrice
                        />
                    </div>
                    
                    <div className={"d-flex align-items-center"}>
                        <span>To</span>
                        <InputText
                            inputTextInputBox_className={"searchResult-actionBar-maxPrice"}
                            inputText_placeholder={"Max."}
                            inputText_onChange={handleOnBlur_maxPrice}
                            isInputOnlyPrice
                        />
                    </div>

                    <PZButton
                        pzButton_className={"searchResult-actionBar-priceFilterButton"}
                        pzButton_onClick={filterPrice}

                        pzButton_isSecondary
                    > 
                        Go
                    </PZButton>
                </div>
            )
        }
    ];

    function openPriceComparisonModal(itemList) {
        setPriceComparisonModalVisibility(true);

        let productListDisplay = [];
        if (func_validation.isArrayNotEmpty(itemList)) {
            productListDisplay = itemList.map((productDetail, idx) => { 

                return (
                    <ProductCard key={idx}    
                        productDetail={productDetail}
                        isBoxShadowRequired
                    />
                );
            })
        }
        setComparisonProductListDisplay(productListDisplay);
    }
    let resultList = [];
    if (func_validation.isArrayNotEmpty(productList)) {
        resultList = productList.map((itemDetail, index) => {    
            if (index >= currentList_pageSize) return "";

            let totalStores = func_validation.getArrayLength(itemDetail);
            let storeLogo = func_validation.getObjectPropertyValue(itemDetail, "storeLogo");
            let affiliateID = func_validation.getObjectPropertyValue(itemDetail, "affiliateID");
            let storeName_init = "";
            if (func_validation.isNullUndefined(storeLogo)) {
                // Find matching image
                for (let i = 0; i < storeList.length; i++) {
                    let storeName = func_validation.getObjectPropertyValue(storeList[i], "name", "");
                    let image = func_validation.getObjectPropertyValue(storeList[i], "image", "");
                    let affiliateID_fromList = func_validation.getObjectPropertyValue(storeList[i], "affiliateID", "");

                    if (affiliateID_fromList == affiliateID) {
                        itemDetail["storeLogo"] = image;
                        storeName_init = storeName;
                    }
                }
            }
            
            return (
                <ProductCard key={index}
                    productDetail={itemDetail}
                    totalStores={totalStores}
                    onClick_comparePrice={() => { openPriceComparisonModal(itemDetail); }}
                />
            );
        });
    }
    else if (func_validation.isArrayEmpty(productSearchList) &&
        func_validation.isBooleanFalseNotNullUndefined(getProductSearchList_requesting)) {
        resultList.push("No product can be found.");
    }
    else {
        resultList.push(
            <Spinner />
        )
    }

    return (
        <React.Fragment>
            <SearchResultLayout
                className={"searchResult"}
                actionBarOptions={actionBarOptions}
                resultList={resultList}
            />

            {func_validation.isArrayNotEmpty(productList) &&
            (!isLoadMoreHidden)? (
                <LoadMoreButton
                    loadContent={getMoreProductList}
                    // offset={pageNo} // 50 is pagesize
                    isLoading={getProductSearchList_requesting}
                />
            ):""}

            <PZLongModal
                isModalOpen={isPriceComparisonModalOpen}
                pzLongModal_header={"Price Comparison"}
                pzLongModal_onModalVisibilityChange={setPriceComparisonModalVisibility}
            >
                <div className={"d-flex flex-wrap"}>
                    {comparisonProductListDisplay}
                </div>
            </PZLongModal>
        </React.Fragment>
    );
}