import { connect } from 'react-redux';

import { couponsActions, countryActions } from 'Dataflow/Actions';

import { CouponsPresentation } from './CouponsPresentation';

function mapStateToProps(state) {
    const { getCoupons_requesting, getCoupons_status, 
            couponList, couponList_meta } = state.couponsReducers;

    const { countryDetails, 
            getCountryList_requesting, countryList } = state.countryReducers;    

	return {
        getCoupons_requesting, 
        getCoupons_status,
        couponList, 
        couponList_meta,

        countryDetails,

        getCountryList_requesting,
        countryList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCoupons: (idx, storeName, offset, affiliateType, pageSize, isoCode) => { dispatch(couponsActions.getCoupons(idx, storeName, offset, affiliateType, pageSize, isoCode)); },

        getCountryDetails: (isoCode) => { dispatch(countryActions.getCountryDetails(isoCode)); },
        getCountryList: () => { dispatch(countryActions.getCountryList()); },
    };
}

const Coupons = connect(mapStateToProps, mapDispatchToProps)(CouponsPresentation);

export default Coupons;