export const func_validation = {
	
    // Null / Undefined

    isNotNull,
    isNotUndefined,
	isNotNullUndefined,
	isNullUndefined,
	
	// Number
	isNumber,
	isNumberValid,
	
    // String

    isString,
	isStringValid,
	isStringEmpty,
	isStringNotEmpty,
	isStringTrimEmpty,
	isStringTrimNotEmpty,
	isStringEqual_caseSensitive,
	isStringEqual_caseInsensitive,
	isStringIncluded_caseIncensitive,
	capitalizeFirstLetterOfEveryWord,

    // Object

	isObject,
	isObjectValid,
	isObjectEmpty,
	isObjectNotEmpty,
	getObjectLength,
	hasObjectProperty,
	getObjectPropertyValue,
	getObjectPropertyValueNotNullUndefined,
	cloneObject,
	isObjectComparisonEqual,
	isObjectComparisonNotEqual,

	// Array
	isArrayValid,
	isArrayEmpty,
	isArrayNotEmpty,
	getArrayLength,
	hasArrayValue,
	cloneArray,
	isComparisonOfBothArraysEqual,
	isComparisonOfBothArraysNotEqual,
	convertArrayToDropdownArrayFormat,

	// Boolean

	isBoolean, 
	isBooleanTrue, 
	isBooleanFalse, 
	isBooleanTrueNotNullUndefined, 
	isBooleanFalseNotNullUndefined,

	// function
	isFunction,
}


/*
 * Null | Undefined
 */

export function isNotNull(val) {
	return (val !== null);
}

export function isNotUndefined(val) {
	return (typeof val !== "undefined");
}

export function isNotNullUndefined(val) {
	return (isNotNull(val) && isNotUndefined(val));
}

export function isNullUndefined(val) {
	return !isNotNullUndefined(val);
}

/*
 * Number
 */

export function isNumber(val) {
	return (typeof val) === "number";
}

export function isNumberValid(val) {
	return isNumber(val) && isNotNullUndefined(val);
}

/*
 * String
 */

export function isString(val) {
    return (typeof val) === "string";
}

export function isStringValid(val) {
	return (isNotNullUndefined(val) && isString(val));
}

export function isStringEmpty(val) {
	return (isStringValid(val) && val == "");
}

export function isStringNotEmpty(val) {
	return (isStringValid(val) && val !== "");
}

export function isStringTrimEmpty(val) {
    return (isStringEmpty(val) && val.toString().trim() == "");
}

export function isStringTrimNotEmpty(val) {
    return (isStringNotEmpty(val) && val.toString().trim() !== "");
}

export function isStringEqual_caseSensitive(str1, str2) {
	return (str1.localeCompare(str2) === 0);
}

export function isStringEqual_caseInsensitive(str1, str2) {
	if (isStringValid(str1) && isStringValid(str2)) {
		let str1_formatted = str1.toUpperCase();
		let str2_formatted = str2.toUpperCase();
	
		return (str1_formatted.localeCompare(str2_formatted) === 0);
	}
	return undefined;
}

export function isStringIncluded_caseIncensitive(str1, str2) {
	if (isStringValid(str1) && isStringValid(str2)) {
		let str1_formatted = str1.toUpperCase();
		let str2_formatted = str2.toUpperCase();
	
		return str1_formatted.includes(str2_formatted);
	}
	return undefined;
}

export function capitalizeFirstLetterOfEveryWord(str) {
	if (isStringTrimNotEmpty(str)) {

		let words = str.toLowerCase().split(" ");

		let finalString = "";
		words.map((word) => {
			if (finalString == "") {
				finalString = word.charAt(0).toUpperCase() + word.slice(1);
			}
			else {
				finalString += " " + word.charAt(0).toUpperCase() + word.slice(1);
			}
		});

		return finalString;
	}

	return ""; 
}


/*
 * Object
 */

export function isObject(obj) {
	return (typeof obj === "object");
}

export function isObjectValid(obj) {
	return (isNotNullUndefined(obj) && isObject(obj));
}

export function isObjectEmpty(obj) {
	return (isObjectValid(obj) && Object.keys(obj).length == 0);
}

export function isObjectNotEmpty(obj) {
	return (isObjectValid(obj) && Object.keys(obj).length > 0);
}

export function getObjectLength(obj) {
	if (isObjectValid(obj)) return Object.keys(obj).length;
	return undefined;
}

export function hasObjectProperty(obj, propName) {
	return (isObjectNotEmpty(obj) && isStringTrimNotEmpty(propName) && 
			isNotNullUndefined(obj[propName]));
}

export function getObjectPropertyValue(obj, propName, defaultVal) {
	if (hasObjectProperty(obj, propName)) return obj[propName];
	if (isNotNullUndefined(defaultVal)) return defaultVal;
	return undefined;
}

export function getObjectPropertyValueNotNullUndefined(obj, propName, defaultVal) {
	if (hasObjectProperty(obj, propName)) {
		if (isNullUndefined(obj[propName]) && 
			isNotNullUndefined(defaultVal)) {
			return defaultVal;
		}
		else return obj[propName];
	}
	if (isNotNullUndefined(defaultVal)) return defaultVal;
	return undefined;
}

export function cloneObject(obj) {
	if (isObjectValid(obj)) return Object.assign({}, obj);
	return {};
}

export function isObjectComparisonEqual(obj1, obj2) {
	if (!isObjectValid(obj1) || !isObjectValid(obj2)) return false;
	else if ((!isObjectEmpty(obj1) && isObjectEmpty(obj2)) || 
		(isObjectEmpty(obj1) && !isObjectEmpty(obj2))) {
		return false;
	}
	else if (isObjectNotEmpty(obj1) &&
		isObjectNotEmpty(obj2)) {

		for (let key in obj1) {
			if (obj1.hasOwnProperty(key) &&
				obj2.hasOwnProperty(key)) {
				if (isObjectValid(obj1[key])) {
					if (!isObjectComparisonEqual(obj1[key], obj2[key])) return false;
				}
				else if (obj1[key] !== obj2[key]) return false;
			}
			else return false;
		}
	}

	return true;
}

export function isObjectComparisonNotEqual(obj1, obj2) {
	return (!isObjectComparisonEqual(obj1, obj2));
}


/*
 * Array
 */

export function isArrayValid(array) {
	return Array.isArray(array);
}

export function isArrayEmpty(array) {
	return (isArrayValid(array) && array.length == 0);
}

export function isArrayNotEmpty(array) {
	return (isArrayValid(array) && array.length > 0);
}

export function getArrayLength(array, defaultValue) {
	if (isArrayValid(array)) return array.length;
	if (isNotNullUndefined(defaultValue)) return defaultValue;
	return undefined;
}

export function hasArrayValue(array, value) {
	return (isArrayNotEmpty(array) && array.includes(value));
}

export function cloneArray(array) {
	if (isArrayValid(array)) return array.slice();
	return [];
}

export function isComparisonOfBothArraysEqual(array1, array2) {
	return JSON.stringify(array1) == JSON.stringify(array2);
}

export function isComparisonOfBothArraysNotEqual(array1, array2) {
	return JSON.stringify(array1) != JSON.stringify(array2);
}

export function convertArrayToDropdownArrayFormat(array) {
	let newArray = [];
	if (isArrayNotEmpty(array)) {
		newArray = array.map((value) => {
			let value_formatted = isStringTrimNotEmpty(value)? value:"";

			return {
				label: value_formatted,
				value: value_formatted,
			};
		});
	}
	return newArray;
}

/*
 * Boolean
 */

export function isBoolean(value) {
	return (typeof value) == 'boolean';
}

export function isBooleanTrue(value) {
	return (isBoolean(value)) && (value == true);
}

export function isBooleanFalse(value) {
	return (isBoolean(value)) && (value == false);
}

export function isBooleanTrueNotNullUndefined(value) {
	return isNotNullUndefined(value) && isBooleanTrue(value);
}

export function isBooleanFalseNotNullUndefined(value) {
	return isNotNullUndefined(value) && isBooleanFalse(value);
}

/*
 * function
 */

export function isFunction(value) {
	return (typeof value) == 'function';
}