import { notificationConstants } from '../Constants';
import { func_validation, func_url } from '../../Functions';

export const notificationActions = {
    getNotificationDetail,
}

function getNotificationDetail(isoCode) {
    return dispatch => {
        dispatch(request());

        const axios = require('../../../node_modules/axios');

        axios.get('/api/getNotificationDetail.php?')
			.then(function (details) {
				// handle success
				dispatch(success(details));
			})
			.catch(function (error) {
				// handle error
				dispatch(failure(error));
			})
			.finally(function () {
				// always executed
			});
    }

    function request(details) { return { type: notificationConstants.GET_NOTIFICATION_DETAILS_REQUESTING, details }; }
    function success(details) { return { type: notificationConstants.GET_NOTIFICATION_DETAILS_SUCCESS, details }; }
    function failure(error) { return { type: notificationConstants.GET_NOTIFICATION_DETAILS_FAILURE, error }; }
}