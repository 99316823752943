import { connect } from 'react-redux';

import { countryActions } from 'Dataflow/Actions';

import ProductSearchResultPresentation from './ProductSearchResultPresentation';

function mapStateToProps(state) {
    const { countryDetails, 
            getCountryList_requesting, countryList } = state.countryReducers;

	return {
        countryDetails,

        getCountryList_requesting,
        countryList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCountryDetails: (isoCode) => { dispatch(countryActions.getCountryDetails(isoCode)); },
        getCountryList: () => { dispatch(countryActions.getCountryList()); },
    };
}

const ProductSearchResult = connect(mapStateToProps, mapDispatchToProps)(ProductSearchResultPresentation);

export default ProductSearchResult;