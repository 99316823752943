import React from 'react';

import 'Scss/Components/searchResultLayout.scss';
import { func_validation } from 'Functions';

export function SearchResultLayout(props) {
    let { className, actionBarClassName, resultClassName,
        resultItemWrapperClassName, resultItemClassName,
        actionBarOptions, resultList } = props;

    // Validate Props
    let actionBar = [];
    if (func_validation.isArrayNotEmpty(actionBarOptions)) {
        actionBar = actionBarOptions.map((details, idx) => {
            let display = func_validation.getObjectPropertyValue(details, "display","");
            let label = func_validation.getObjectPropertyValue(details, "label","");
            let itemClassName = func_validation.getObjectPropertyValue(details, "itemClassName","");
            let itemLabelClassName = func_validation.getObjectPropertyValue(details, "itemLabelClassName","");
            let itemDisplayClassName = func_validation.getObjectPropertyValue(details, "itemDisplayClassName","");

            return (
                <div key={idx} 
                    className={"searchResultLayout-actionbar-item d-flex align-items-center " +
                                (itemClassName? itemClassName + " ":"")}>
                    <div className={"searchResultLayout-item-label " +
                                    (itemLabelClassName? itemLabelClassName + " ":"")}>
                        {label}
                    </div>

                    <div className={"searchResultLayout-item-display " +
                                    (itemDisplayClassName? itemDisplayClassName + " ":"")}>
                        {display}
                    </div>
                </div>
            );
        });
    }
    let results = [];
    if (func_validation.isArrayNotEmpty(resultList)) {
        results = resultList.map((display, idx) => {
            return (
                <div key={idx} 
                    className={"searchResultLayout-resultItemWrapper " + 
                                (resultItemWrapperClassName? resultItemWrapperClassName + " ":"")}>
                    <div className={"searchResultLayout-resultItem " +
                                    (resultItemClassName? resultItemClassName + " ":"")}>
                        {display}
                    </div>
                </div>
            );
        });
    }

    return (
        <div className={"searchResultLayout " + 
                        (className? className + " ":"")}>
            {/* Action Bar */}
            <div className={"searchResultLayout-actionBar d-md-flex align-items-center " +
                            (actionBarClassName? actionBarClassName + " ":"")}>
                {actionBar}
            </div>

            {/* Result */}
            <div className={"searchResultLayout-result d-flex justify-content-center flex-wrap " + 
                            (resultClassName? resultClassName + " ":"")}>
                {results}
            </div>
        </div>
    );
}