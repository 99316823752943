import { advertisementConstants } from '../Constants';
import { func_validation } from '../../Functions';

export function advertisementReducers(state = {}, action) {
    switch (action.type) {
        
        // Get Store Advertisement Details

        case advertisementConstants.GET_STORE_ADVERTISEMENT_DETAILS_REQUESTING:
            return {
                ...state,
                getStoreAdvertisementDetails_requesting: true, getStoreAdvertisementDetails_status: false,
            }
        case advertisementConstants.GET_STORE_ADVERTISEMENT_DETAILS_SUCCESS:
            return {
                ...state,
                getStoreAdvertisementDetails_requesting: false, getStoreAdvertisementDetails_status: true,
                storeAdvertisementDetails: action.details.data,
            }
        case advertisementConstants.GET_STORE_ADVERTISEMENT_DETAILS_FAILURE:
            return {
                ...state,
                getStoreAdvertisementDetails_requesting: false, getStoreAdvertisementDetails_status: false, 
            }

        // Toggle Top Bar Prompt Visibility

        case advertisementConstants.TOGGLE_TOP_BAR_PROMPT_VISIBILITY_REQUESTING:
            return {
                ...state,
                toggleTopBarPromptVisibility_requesting: true, toggleTopBarPromptVisibility_status: false,
            }
        case advertisementConstants.TOGGLE_TOP_BAR_PROMPT_VISIBILITY_SUCCESS:
            let isTopBarPromptVisible = !state.isTopBarPromptVisible;

            return {
                ...state,
                toggleTopBarPromptVisibility_requesting: false, toggleTopBarPromptVisibility_status: true,
                isTopBarPromptVisible: isTopBarPromptVisible,
            }
        case advertisementConstants.TOGGLE_TOP_BAR_PROMPT_VISIBILITY_FAILURE:
            return {
                ...state,
                toggleTopBarPromptVisibility_requesting: false, toggleTopBarPromptVisibility_status: false, 
            }

        default:
            return state;
    }
}