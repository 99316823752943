import { couponsConstants } from '../Constants';
import { func_url, func_validation } from '../../Functions';

export const couponsActions = {
	getCoupons,
};

function getCoupons(idx, storeAffiliateID, offset, affiliateType, pageSize, isoCode) {
    return dispatch => {
        dispatch(request());

		const axios = require('../../../node_modules/axios');

		let param = "";
		if (func_validation.isNumberValid(idx)) param += "categoryIdx=" + encodeURIComponent(idx);
		if (func_validation.isStringTrimNotEmpty(storeAffiliateID)) param += "&storeAffiliateID=" + encodeURIComponent(storeAffiliateID);
		if (func_validation.isNumberValid(offset)) param += "&offset=" + encodeURIComponent(offset);
		if (func_validation.isStringTrimNotEmpty(affiliateType)) param += "&affiliateType=" + encodeURIComponent(affiliateType);
		if (func_validation.isNumberValid(pageSize)) param += "&pageSize=" + encodeURIComponent(pageSize);
		if (func_validation.isStringTrimNotEmpty(isoCode)) param += "&isoCode=" + encodeURIComponent(isoCode);

        axios.get('/api/getCoupons.php?' + param)
			.then(function (details) {
				// handle success
				dispatch(success(details));
			})
			.catch(function (error) {
				// handle error
				dispatch(failure(error));
			})
			.finally(function () {
				// always executed
			});
    }

    function request(details) { return { type: couponsConstants.GET_COUPONS_REQUESTING, details }; }
    function success(details) { return { type: couponsConstants.GET_COUPONS_SUCCESS, details }; }
    function failure(error) { return { type: couponsConstants.GET_COUPONS_FAILURE, error }; }
}