import { combineReducers } from 'redux';
import { advertisementReducers } from './advertisement.reducers';
import { countryReducers } from './country.reducers';
import { couponsReducers } from './coupons.reducers';
import { notificationReducers } from './notification.reducers';
import { productsReducers } from './products.reducers';
import { sidebarReducers } from './sidebar.reducers';
import { storesReducers } from './stores.reducers';

const rootReducer = combineReducers({
    advertisementReducers,
    countryReducers,
    couponsReducers,
    notificationReducers,
    productsReducers,	
    sidebarReducers,
    storesReducers,
});

export default rootReducer;