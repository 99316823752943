import React from 'react';

import '../Scss/Components/pzHamburger.scss';

class PZHamburger extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.__toggleSidebarVisibility = this.__toggleSidebarVisibility.bind(this);
    }

    __toggleSidebarVisibility() {
        window.document.body.style.overflow = "hidden";

        if (this.props.toggleSidebarVisibility) this.props.toggleSidebarVisibility();
    }


    render() {
        const { pzHamburger_className } = this.props;

        return(
            <div className={"pzHamburger  d-flex align-items-center justify content-between " + 
                            (pzHamburger_className? pzHamburger_className + " ":"")}
                onClick={this.__toggleSidebarVisibility}
            >
                
                <span className={"pzHamburger-lines"} />
            </div>
        );
    }
}

export { PZHamburger };