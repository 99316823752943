export function sidebarReducers(state = {}, action) {
    switch (action.type) {

        // Toggle Sidebar Visibility

        case "success":
            const { isSidebarVisible } = state;
            
            return {
                ...state,
                requesting: false, 
                isSidebarVisible: !isSidebarVisible
            }
        
        default:
            return state;
    }
}