import React, { useState, useEffect } from 'react';

import { useParams, useLocation } from "react-router-dom";

import { PZButton, StoreCard, Spinner, LoadMoreButton, GeneralLayout } from 'Components';
import CouponModal from './Modal/CouponModal';

import { func_validation } from 'Functions';

import { categoriesConstants } from 'Dataflow/Constants';

import 'Scss/Modules/stores.scss';



const CATEGORY_LIST = categoriesConstants.generalCategories();

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function StoresPresentation(props) {
    //  Props
    const { getStores_requesting, storeList: storeList_props, metadata,
            getStoreAdvertisementDetails_requesting, storeAdvertisementDetails,
            countryDetails,
            getCountryList_requesting, countryList,
            history } = props;

    // States
    const [isModalOpen, setModalVisiblity] = useState(false);
    const [selectedStoreName, setSelectedStoreName] = useState("");
    const [listOffset, setListOffset] = useState(0);
    const [categoryIdx, setCategoryIdx] = useState(0);
    const [storeList, setStoreList] = useState([]);

    let { isoCode, category, advertisementId } = useParams();
    if (func_validation.isNullUndefined(isoCode)) isoCode = "WW"; // default to Worldwide
    if (func_validation.isNullUndefined(category)) category = "All";
    let query = useQuery();

    // Get Store List
    function getCategoryPosition(categoryName) {
        // Find matching category
        for (let i = 0; i < CATEGORY_LIST.length; i++) {
            let category = func_validation.getObjectPropertyValue(CATEGORY_LIST[i], "value");

            if (func_validation.isStringEqual_caseInsensitive(categoryName, category))
                return i;
        }
        return 0;
    }
    function getStoreList(offset = 0) {
        // Store name
        let storeName = query.get("storeName");
        storeName = func_validation.isStringTrimNotEmpty(storeName)? storeName: "";

        // Category Index
        let categoryIdx = getCategoryPosition(category);
        setCategoryIdx(categoryIdx);

        if (props.getStores) {
            setListOffset(offset);
            props.getStores(categoryIdx, storeName, offset, null, isoCode);
        }
    }
    useEffect(() => {
        getStoreList();
    },[]);

    // Update Store List
    useEffect(() => {
        const { storeList: storeList_new, getStores_status } = props;

        if (func_validation.isArrayValid(storeList_new) &&
            func_validation.isBooleanTrueNotNullUndefined(getStores_status)) {

            let storeList_formatted = [];
            if (listOffset) storeList_formatted = storeList.concat(storeList_new);
            else storeList_formatted = func_validation.cloneArray(storeList_new);

            setStoreList(storeList_formatted);
        }
    }, [getStores_requesting]);


    function toggleModalVisiblity(storeName) {
        let storeName_formatted = (!isModalOpen)? storeName:"";

        setModalVisiblity(!isModalOpen);
        setSelectedStoreName(storeName_formatted);
    }

    // Loading Content
    let isLoading = false;
    if (func_validation.isBooleanTrueNotNullUndefined(getStores_requesting) &&
        func_validation.isArrayEmpty(storeList)) isLoading = true;

    // Loading List
    let isListLoading = false;
    if (func_validation.isBooleanTrueNotNullUndefined(getStores_requesting)) isListLoading = true;

    // Page size
    let pageSize = func_validation.getObjectPropertyValue(metadata, "pageSize", 0);

    // Result
    let result = "";
    if (func_validation.isArrayNotEmpty(storeList)) {
        result = storeList.map((storeDetail, idx) => {
            let storeName = func_validation.getObjectPropertyValue(storeDetail, "StoreName");
            let storeImage = func_validation.getObjectPropertyValue(storeDetail, "StoreImage");

            let columnName = "";
            if (categoryIdx == 0) columnName = "Homepage";
            else if (func_validation.isObjectNotEmpty(CATEGORY_LIST[categoryIdx])) {
                columnName = CATEGORY_LIST[categoryIdx].value;
            }

            let urlRedirection = func_validation.getObjectPropertyValue(storeDetail, columnName);

            return (
                <StoreCard {...props} key={idx}
                    storeCard_imageSrc={storeImage}
                    storeCard_imageAlt={storeName}
                    storeCard_name={storeName}
                    storeCard_description={(
                        <PZButton
                            pzButton_className={""}
                            pzButton_onClick={() => { toggleModalVisiblity(storeName); }}

                            pzButton_isSecondary
                        >
                            {"View Deals"}
                        </PZButton>
                    )}

                    storeCard_storeUrl={urlRedirection}
                    isOpenUrlInNewTab

                    isStoreCardNameClickable
                />
            );
        });
    }

    return (
        <GeneralLayout
            pageHeaderText={"Stores"}

            getAdvertisementDetails_requesting={getStoreAdvertisementDetails_requesting}
            advertisementDetails={storeAdvertisementDetails}
            getAdvertisementDetails={props.getStoreAdvertisementDetails}

            countryList={countryList}
            countryDetails={countryDetails}
            getCountryList_requesting={getCountryList_requesting}
            getCountryList={props.getCountryList}
            
            getCountryDetails={props.getCountryDetails}

            isoCode={isoCode}
            category={category}
            advertisementId={advertisementId}

            mainUrlPathname={"/stores/"}
            history={history}
        >
            <div className="stores d-flex flex-column align-items-center">
                {/* Result */}
                {(!isLoading)? (
                    <React.Fragment>
                        <div className={"stores-body d-flex justify-content-center flex-wrap "}>
                            {result}
                        </div>

                        {func_validation.isArrayNotEmpty(storeList_props) && 
                        (storeList.length >= pageSize)? (
                            <LoadMoreButton
                                loadContent={getStoreList}
                                offset={listOffset + pageSize}
                                isLoading={isListLoading}
                            />
                        ):""}
                    </React.Fragment>
                ):(
                    <div className={"stores-loader d-flex justify-content-center align-items-center"}>
                        <Spinner/>
                    </div>
                )}

                {/* Modal */}
                {isModalOpen? (
                    <CouponModal {...props}
                        isModalOpen={isModalOpen}
                        updateParentState={toggleModalVisiblity}
                        storeName={selectedStoreName}
                    />
                ):""}
            </div>
        </GeneralLayout>
    );
}

export default StoresPresentation;