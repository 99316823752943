import { func_validation } from './func_validations';

export const func_data = {
    requestSuccessful,

    getHeaderDetails,
}

function requestSuccessful(requestingPropName, statusPropName, onSuccess, prevProps, props) { 
    let requesting_prev = func_validation.getObjectPropertyValue(prevProps, requestingPropName);
    let status = func_validation.getObjectPropertyValue(props, statusPropName);

    if (func_validation.isBooleanTrueNotNullUndefined(requesting_prev) &&
        func_validation.isBooleanTrueNotNullUndefined(status) &&
        func_validation.isFunction(onSuccess))
        onSuccess();
}

function getHeaderDetails(urlPath) {
    return func_validation.getObjectPropertyValue(headerDetails, urlPath, {});
}

const headerDetails = {
    "/coupons": {
        "pageTitle": "Coupons & Discounts from Hundreds of Online Stores on Your Fingertip",
        "pageDescription": "Latest coupons, promo codes, discounts & vouchers for your online shopping. Find best India deals, sales & promotions here.",
        "pageKeyword": "coupons, codes, discount codes, promo codes, discount coupons, vouchers, voucher codes, deals, promotions, offers, online shopping, sales, promotions, discounts",
    },
    "/stores": {
        "pageTitle": "Online Shopping Site for Mobiles, Electronics, Furniture, Grocery, Lifestyle, Books & More. Best Offers!",
        "pageDescription": "India's biggest online store for Mobiles, Fashion (Clothes/Shoes), Electronics, Home Appliances, Books, Home, Furniture, Grocery, Jewelry, Sporting goods, Beauty & Personal Care and more! Find the largest selection from all brands at the lowest prices in India. Payment options - COD, EMI, Credit card, Debit card &amp; more.",
        "pageKeyword": "Online Shopping in India,online Shopping store,Online Shopping Site,Buy Online,Shop Online,Online Shopping,Powzie",
    },
    "/stores/Auto": {
        "pageTitle": "Best Deals from Top Auto Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online auto stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "car accessories,auto parts,motorcycle parts",
    },
    "/stores/BooksEducations": {
        "pageTitle": "Best Deals from Top Books & Educations Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online books & educations stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "ebooks, ebooks.com, e-books, ebook.com, ebooks online, digital books, electronic books, ebook, e books, e book, e-book, ebooks download, e books online, ebook store, buy ebooks, Online books, Buy book online, Buy ebooks online, Ebooks textbooks, ebook online, buy ebook, Ebook reader, Epub, pdf, Android, Iphone, Ipad, Tablet, Samsung",
    },
    "/stores/Business": {
        "pageTitle": "Best Deals from Top Business Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online business stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "business, tech, leadership, entrepreneurs, investing",
    },
    "/stores/CarRentalTransport": {
        "pageTitle": "Best Deals from Top Car Rental & Transport Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online car rental & transport stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "auto europe, car rental, rental car, car hire, international car rental, europe, chauffeur, peugeot open europe, lease, travel deals",
    },
    "/stores/ChildrenBaby": {
        "pageTitle": "Best Deals from Top Children & Baby Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online children & baby stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Kids Clothes, Kids Wear, Children Wear, Children Dresses, Kids Dresses, Kids Sale, Kids Offers, Kids Clothes Sale",
    },
    "/stores/Clothing": {
        "pageTitle": "Best Deals from Top Clothing Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online clothing stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Womens Clothing, Womens Clothing Online, Ladies Clothes, Online Shopping for Womens Clothing, Mens Clothing, Mens Clothing Online, Gentlemens Clothes, Online Shopping for Mens Clothing, Clothing Online, Clothes, Clothing",
    },
    "/stores/ComputerElectronics": {
        "pageTitle": "Best Deals from Top Computer & Electronics Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online computer & electronics stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "laptops, buy laptops online, india, price, deals, store, offers",
    },
    "/stores/Cosmetics": {
        "pageTitle": "Best Deals from Top Cosmetics Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online cosmetics stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Makeup store, makeup, buy makeup online, online makeup, shop makeup online, lipstick",
    },
    "/stores/DepartmentStores": {
        "pageTitle": "Best Deals from Top Department Stores Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online department stores stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "global online shopping, Books, Online Shopping, Book Store, Magazine, Subscription, Music, CDs, DVDs, Videos, Electronics, Video Games, Computers, Cell Phones, Toys, Games, Apparel, Accessories, Shoes, Jewelry, Watches, Office Products, Sports & Outdoors, Sporting Goods, Baby Products, Health, Personal Care, Beauty, Home, Garden, Bed & Bath, Furniture, Tools, Hardware, Vacuums, Outdoor Living, Automotive Parts, Pet Supplies, Broadband, DSL",
    },
    "/stores/Entertainment": {
        "pageTitle": "Best Deals from Top Entertainment Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online entertainment stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Movies Store, Movies Store Online Shopping, virtual reality headsets, vr headsets, virtual reality goggles",
    },
    "/stores/FamilyFunCostume": {
        "pageTitle": "Best Deals from Top Family Fun & Costume Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online family fun & costume stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "costumes & cosplay, costumes & cosplay from China, costumes & cosplay suppliers",
    },
    "/stores/FinancialServices": {
        "pageTitle": "Best Deals from Top Financial Services Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online financial services stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "credit card application, credit card apply, credit card apply online, online credit card application, online credit card apply, apply for credit card, apply credit card online",
    },
    "/stores/Flights": {
        "pageTitle": "Best Deals from Top Flights Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online flights stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Book Cheap Air Tickets, Cheap Flights Booking Online, Cheap Air Tickets, Flight Tickets, Domestic Flights, International Flights, Flight status, Cheap Flights, Lowest Airfares Tickets",
    },
    "/stores/Flowers": {
        "pageTitle": "Best Deals from Top Flowers Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online flowers stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "send flowers, flowers online, same day flower delivery, order flowers online, online flower delivery, send flowers online, flowers to india, online flowers, fresh flowers, flower delivery, buy flowers",
    },
    "/stores/FoodDrink": {
        "pageTitle": "Best Deals from Top Food & Drink Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online food drink stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "online grocery,online grocery delhi,online grocery bangalore, online grocery shopping, online grocery store,online supermarket,grocery chennai,grocery pune, online grocery shopping india, buy groceries online,online grocery bangalore online vegetable store, food shopping online,Online Grocery mysore, online grocery patna",
    },
    "/stores/GamesToys": {
        "pageTitle": "Best Deals from Top Games & Toys Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online games & toys stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "toys, kids toys, toys online, online toy stores, toys online shopping, toy store, Toysclp Store, Toysclp Store Online Shopping",
    },
    "/stores/Gift": {
        "pageTitle": "Best Deals from Top Gift Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online gift stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "buy Festival Gifts & Party Supplies, Festival Gifts & Party Supplies supplier, wholesale Festival Gifts & Party Supplies, India Festival Gifts & Party Supplies, Festival Gifts & Party Supplies on sale, new Festival Gifts & Party Supplies, India wholesale Festival Gifts & Party Supplies, high quality Festival Gifts & Party Supplies, online Festival Gifts & Party Supplies store",
    },
    "/stores/Glasses": {
        "pageTitle": "Best Deals from Top Glasses Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online glasses stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Sunglasses for Women, Buy Sunglasses Online, Fashion Ladies Sunglasses, Sunglasses, Sunglasses Women luxury fashion, Shop online Sunglasses Women luxury fashion, sunglasses, sunglasses online, online sunglasses, buy sunglasses, sunglasses in india, sunglasses shopping",
    },
    "/stores/HandbagLuggage": {
        "pageTitle": "Best Deals from Top Handbag & Luggage Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online handbag & luggage stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Cheap Bags, Cute Bags, Bags for Women, Bags Online, Leather Bags, Bags, Bags Women luxury fashion, Shop online Bags Women luxury fashion",
    },
    "/stores/Health": {
        "pageTitle": "Best Deals from Top Health Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online health stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "The Body Shop, The Body Shop India, New Year Sale, Gift Sets, face care, skincare, hair care, makeup products, vegan beauty products india, vegan products, vegan beauty products, valentine's gifts, vitamins, supplements, beauty, natural health, herbs,  discount vitamins, discount supplements, online vitamins, buy vitamins, buy supplements, vitamins india",
    },
    "/stores/HobbiesCollectibles": {
        "pageTitle": "Best Deals from Top Hobbies & Collectibles Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online hobbies & collectibles stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Fishing Lures Directory,Fishing Lures,Fishing,Sports & Entertainment, Online Shopping Fishing Lures, Discount Arts and Crafts, Art Supplies, Craft Supplies, Craft Products, Artist Materials, Art Materials, Art Supplies for Kids, Craft Tools, Craft Kits, Oil Paint, Acrylic Paint, Buy Canvas, Scrapbook Products, Artist Brushes, Watercolors, Sketching",
    },
    "/stores/HomeLiving": {
        "pageTitle": "Best Deals from Top Home & Living Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online home & living stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Modern Home Decor, Gardening Tools, Wholesale Home Decor, Bathroom Accessories Sets, Home & Kitchen Store",
    },
    "/stores/Hotels": {
        "pageTitle": "Best Deals from Top Hotels Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online hotels stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Budget Hotels, Guest House, Budget Hotels in India, Guest House in India, Cheap Hotels in India, hotels, rooms, accommodations, discounts, reservations, hotel, booking, luxury, deals, rates, hotel deals, accommodation",
    },
    "/stores/Jewelry": {
        "pageTitle": "Best Deals from Top Jewelry Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online jewelry stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Jewelry, Women's Jewelry, Cheap Jewelry, Jewelry Online, Cute Jewelry, Cool Jewelry",
    },
    "/stores/Maternity": {
        "pageTitle": "Best Deals from Top Maternity Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online maternity stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "maternity, Wholesale maternity , Dropship maternity, Maternity Wear, Pregnancy Clothes, Maternity Dresses, Maternity Bra, Maternity Panties, Feeding Nighties, Maternity Clothes Online Shopping",
    },
    "/stores/Office": {
        "pageTitle": "Best Deals from Top Office Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online office stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "The Stationery Store, The Stationery Store Online Shopping, Office supplies, office accessories,Office Furniture,Office Machine",
    },
    "/stores/Perfumes": {
        "pageTitle": "Best Deals from Top Perfumes Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online perfumes stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Perfume, Parfum Intense, Forest Essentials, pure essential oils, Kashmiri Nargis, Madurai Jasmine, forest essentials perfumes, body perfumes for women",
    },
    "/stores/Pets": {
        "pageTitle": "Best Deals from Top Pets Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online pets stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "Pet Supplies Online, Dog Supplies, Cat Supplies, Pet Aquarium, Pet Supplies, Pet Care Supplies, Pet Health Supplement, Pet Care Products Online, flea & tick Control",
    },
    "/stores/Shoes": {
        "pageTitle": "Best Deals from Top Shoes Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online shoes stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "shoes in India, Shoes, Buy Shoes Online, Bata Shoes Online India, Bata Online, Formal Shoes, Leather Shoes, Casual Shoes, Mens Footwear, Womens Footwear, Kids Shoes, Shoes Online Shopping",
    },
    "/stores/SportsFitness": {
        "pageTitle": "Best Deals from Top Sports & Fitness Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online sports & fitness stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "shoes, shirts, sandals, shorts, tops, pants, sweatshirts, footwear, sports",
    },
    "/stores/Telecommunications": {
        "pageTitle": "Best Deals from Top Telecommunications Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online telecommunications stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "smartphones, smartphone, samsung smartphones, samsung galaxy, samsung galaxy mobile, galaxy mobiles, samsung galaxy android, cheap android phones,5G PHONES,android phone 2019,4G android phone,android phones from india",
    },
    "/stores/Watches": {
        "pageTitle": "Best Deals from Top Watches Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online watches stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "buy watches, men's watches, women's watches, rolex watches, tag heuer watches, breitling watches, omega watches, cartier, montblanc, luxury discount watches for sale, free overnight shipping, Fashion Quartz Watches, Mechanical Watches, Digital Watches,Dual Display Watches",
    },
    "/stores/WebHosting": {
        "pageTitle": "Best Deals from Top Web Hosting Stores All-in-One",
        "pageDescription": "Get coupons at your favorite online web hosting stores via Powzie. Lots of discount for a limited time offer.",
        "pageKeyword": "hosting, web hosting, domains, linux hosting, vps hosting, fast hosting, website hosting, domain names, website builder, cloud hosting, wordpress hosting",
    }
}