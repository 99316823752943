import React, { useEffect, useState } from 'react';

import { PZLongModal, CouponList } from 'Components';
import { func_validation } from 'Functions';



export default function CouponModalPresentation(props) {
    const { isModalOpen,
            getStoreDetails_requesting,
                
            couponList, couponList_meta, 
            
            getCoupons_status, getCoupons_requesting,
            storeName,
            history } = props;

    const [storeDetails, setStoreDetails] = useState({});
    const [isInitRender, setInitRenderStatus] = useState(true);

    useEffect(() => { setInitRenderStatus(true); });

    function getStoreDetails() {
        const { storeName } = props;

        if (func_validation.isStringTrimNotEmpty(storeName) &&
            props.getStoreDetails) {

            props.getStoreDetails(storeName);
        }
    }
    useEffect(() => {
        getStoreDetails();
    }, []);

    function updateStoreDetailState() {
        const { storeDetails: storeDetails_props } = props;

        if (func_validation.isObjectNotEmpty(storeDetails_props) &&
            func_validation.isBooleanFalseNotNullUndefined(getStoreDetails_requesting) &&
            !isInitRender) {

            setStoreDetails(storeDetails_props);
        }
    }
    useEffect(() => {
        updateStoreDetailState();
    }, [getStoreDetails_requesting]);

    let storeImage = "";
    let storeDealsLink = "";
    let storeAffiliateID = "";
    let affiliateProgram = "";
    if (func_validation.isObjectNotEmpty(storeDetails)) {
        // Image
        storeImage = func_validation.getObjectPropertyValue(storeDetails, "StoreImage");

        // Link
        storeDealsLink = func_validation.getObjectPropertyValue(storeDetails, "DealsPage","");
        if (!func_validation.isStringTrimNotEmpty(storeDealsLink))
            storeDealsLink = func_validation.getObjectPropertyValue(storeDetails, "Homepage","");

        // Affiliate Detail
        storeAffiliateID = func_validation.getObjectPropertyValue(storeDetails, "AffiliateID","");   
        affiliateProgram = func_validation.getObjectPropertyValue(storeDetails, "affiliateProgram","");   
    }

    useEffect(() => { setInitRenderStatus(false); });

    return (   
        <PZLongModal
            isModalOpen={isModalOpen}
            updateParentState={props.updateParentState}
            pzLongModal_buttonTriggerLabel={"View Deals"}
            defaultHeaderTxt={"Deals"}
        >
            <CouponList
                history={history}

                getCoupons_requesting={getCoupons_requesting}
                getCoupons_status={getCoupons_status}

                storeName={storeName}
                listID={storeAffiliateID}

                defaultCouponImage={storeImage}
                defaultCouponLink={storeDealsLink}

                couponList={couponList}
                couponList_meta={couponList_meta}
                couponRedirectionLink={storeDealsLink}

                affiliateProgram={affiliateProgram}

                getList={props.getCoupons}

                isListIDRequired
            />
        </PZLongModal>
    );
}