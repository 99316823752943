import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { func_validation, func_utilities, func_url } from 'Functions';

import miniAppLogo from 'Images/simplified_logo.png';
import google_play_store from 'Images/google_play_store.png';

import 'Scss/Modules/topBarPrompt.scss';

class TopBarPromptPresentation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }

        this.handleVisibility = this.handleVisibility.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    componentDidMount() {
        this.handleVisibility();
    }

    handleVisibility() {
        if (this.props.toggleTopBarPromptVisibility &&
            func_utilities.isMobile())
            this.props.toggleTopBarPromptVisibility();
    }

    handleOnClick() {
        func_url.openUrlInNewTab("https://play.google.com/store/apps/details?id=com.powzie.app&hl=en");
    }

    render() {
        const { isTopBarPromptVisible } = this.props;

        if (func_validation.isBooleanFalseNotNullUndefined(isTopBarPromptVisible) ||
            (!func_utilities.isMobile())) return "";

        return(
            <div id={"powzieTopBarPrompt"} // This is used for removing this component manually in Google Play App.
                className={"topBarPrompt d-flex align-items-center justify-content-center"}>
                {/* Close Button */}
                <div className={"topBarPrompt-closeButton"} onClick={this.handleVisibility}>
                    X
                </div>

                <div className={"topBarPrompt-contentWrapper d-flex align-items-center"}
                    onClick={this.handleOnClick}
                >
                    {/* App Logo */}
                    <img className={"topBarPrompt-appLogo"}
                        src={miniAppLogo}
                        alt={"App Logo"}
                    />

                    {/* Message */}
                    <div className={"topBarPrompt-message"}>
                        For Better Experience
                    </div>

                    {/* Call to Action */}
                    <div className={"topBarPrompt-actionWrapper"}>
                        <img className={"topBarPrompt-actionImage"}
                            src={google_play_store}
                            alt={"Google Play Image"}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default TopBarPromptPresentation;