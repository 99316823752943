import React, { useState, useEffect} from 'react';

import { Spinner } from './Spinner';

import { func_validation } from '../Functions/func_validations';

import '../Scss/Components/pzLongModal.scss';

export function PZLongModal(props) {
    const { className, modalWrapperClassName, isCloseButtonHidden, isModalOpen: isModalOpen_props, 
            defaultHeaderTxt, pzLongModal_header, pzLongModal_isLoading, pzLongModal_footer } = props;

    const [isModalOpen, setModalVisibility] = useState(false);

    function updateModalState() {
        setModalVisibility(isModalOpen_props);
    }
    useEffect(() => {
        updateModalState();
    }, [isModalOpen_props]);

    function toggleModalVisibility() {
        setModalVisibility(!isModalOpen);

        if (func_validation.isFunction(props.updateParentState)) {
            props.updateParentState(!isModalOpen);
        }

        if (func_validation.isFunction(props.pzLongModal_onModalVisibilityChange))
            props.pzLongModal_onModalVisibilityChange();
    }

    if (!isModalOpen) return "";

    let pzLongModal_header_formatted = func_validation.isStringTrimNotEmpty(defaultHeaderTxt)? defaultHeaderTxt: "";
    if (func_validation.isStringTrimNotEmpty(pzLongModal_header)) 
        pzLongModal_header_formatted = pzLongModal_header;

    return(   
        <div id={"pzLongModal"} 
            className={"pzLongModal " +
                        (className? className + " ":"")}>
            {/* Modal */}
            <div className={"pzLongModal-modal"}>
                <div className={"pzLongModal-modalBackground"}
                    onClick={toggleModalVisibility}
                />
                
                <div className={"row"}>
                    <div className={"pzLongModal-modalWrapper col-11 d-flex flex-column " +
                                    (modalWrapperClassName? modalWrapperClassName + " ":"")}>
                        {/* Close Button */}
                        {!isCloseButtonHidden? (
                            <span className={"pzLongModal-close"}
                                onClick={toggleModalVisibility}
                            >
                                X
                            </span>
                        ):""}

                        {/* Modal Header */}
                        <div className={"pzLongModal-modalHeader text-center"}>
                            <span>{pzLongModal_header_formatted}</span>
                        </div>

                        {/* Modal Body */}
                        <div className={"pzLongModal-modalBody "}>
                            {pzLongModal_isLoading? (
                                <Spinner/>
                            ): props.children}
                        </div>

                        {/* Modal Footer */}
                        <div className={"pzLongModal-modalFooter d-flex align-items-center justify-content-center "}>
                            {pzLongModal_footer}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}