import { advertisementConstants } from '../Constants';
import { func_validation, func_url } from '../../Functions';

export const advertisementActions = {
    getStoreAdvertisementDetails,
    
    toggleTopBarPromptVisibility,
};

function getStoreAdvertisementDetails(advertisementId) {
    return dispatch => {
        dispatch(request());

        const axios = require('../../../node_modules/axios');

        let param = "";
        if (func_validation.isStringTrimNotEmpty(advertisementId)) param += "&advertisementId=" + advertisementId;

        axios.get('/api/getStoreAdvertisementDetails.php?' + param)
			.then(function (details) {
				// handle success
				dispatch(success(details));
			})
			.catch(function (error) {
				// handle error
				dispatch(failure(error));
			})
			.finally(function () {
				// always executed
			});
    }

    function request(details) { return { type: advertisementConstants.GET_STORE_ADVERTISEMENT_DETAILS_REQUESTING, details }; }
    function success(details) { return { type: advertisementConstants.GET_STORE_ADVERTISEMENT_DETAILS_SUCCESS, details }; }
    function failure(error) { return { type: advertisementConstants.GET_STORE_ADVERTISEMENT_DETAILS_FAILURE, error }; }
}

function toggleTopBarPromptVisibility() {
    return dispatch => {
        dispatch(success());
    }

    function request(details) { return { type: advertisementConstants.TOGGLE_TOP_BAR_PROMPT_VISIBILITY_REQUESTING, details }; }
    function success(details) { return { type: advertisementConstants.TOGGLE_TOP_BAR_PROMPT_VISIBILITY_SUCCESS, details }; }
    function failure(error) { return { type: advertisementConstants.TOGGLE_TOP_BAR_PROMPT_VISIBILITY_FAILURE, error }; }
}


