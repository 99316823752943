import { countryConstants } from '../Constants';

export function countryReducers(state = {}, action) {
    switch (action.type) {
        
        // Get Country Details

        case countryConstants.GET_COUNTRY_DETAILS_REQUESTING:
            return {
                ...state,
                getCountryDetails_requesting: true, getCountryDetails_status: false,
            }
        case countryConstants.GET_COUNTRY_DETAILS_SUCCESS:
            return {
                ...state,
                getCountryDetails_requesting: false, getCountryDetails_status: true,
                countryDetails: action.details.data,
            }
        case countryConstants.GET_COUNTRY_DETAILS_FAILURE:
            return {
                ...state,
                getCountryDetails_requesting: false, getCountryDetails_status: false, 
            }

        // Get Country List

        case countryConstants.GET_COUNTRY_LIST_REQUESTING:
            return {
                ...state,
                getCountryList_requesting: true, getCountryList_status: false,
            }
        case countryConstants.GET_COUNTRY_LIST_SUCCESS:
            return {
                ...state,
                getCountryList_requesting: false, getCountryList_status: true,
                countryList: action.details.data,
            }
        case countryConstants.GET_COUNTRY_LIST_FAILURE:
            return {
                ...state,
                getCountryList_requesting: false, getCountryList_status: false, 
            }

        default:
            return state;
    }
}