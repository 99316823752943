import { productsConstants } from '../Constants';

export function productsReducers(state = {}, action) {
    switch (action.type) {

        // Get Product Suggetsions

        case productsConstants.GET_PRODUCT_SUGGESTIONS_REQUESTING:
            return {
                ...state,
                getProductSuggestions_requesting: true, getProductSuggestions_status: false,
                productNameList: undefined,
            }
        case productsConstants.GET_PRODUCT_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                getProductSuggestions_requesting: false, getProductSuggestions_status: true,
                productNameList: action.details.data
            }
        case productsConstants.GET_PRODUCT_SUGGESTIONS_FAILURE:
            return {
                ...state,
                getProductSuggestions_requesting: false, getProductSuggestions_status: false, 
                productNameList: undefined,
            }

        // Get Product Search List

        case productsConstants.GET_PRODUCT_SEARCH_LIST_REQUESTING:
            return {
                ...state,
                getProductSearchList_requesting: true, getProductSearchList_status: false,
                productSearchList: undefined,
            }
        case productsConstants.GET_PRODUCT_SEARCH_LIST_SUCCESS:
            return {
                ...state,
                getProductSearchList_requesting: false, getProductSearchList_status: true,
                productSearchList: action.details.data
            }
        case productsConstants.GET_PRODUCT_SEARCH_LIST_FAILURE:
            return {
                ...state,
                getProductSearchList_requesting: false, getProductSearchList_status: false, 
                productSearchList: undefined,
            }

        // Get Store List

        case productsConstants.GET_STORE_LIST_REQUESTING:
            return {
                ...state,
                getStoreList_requesting: true, getStoreList_status: false,
                storeList: undefined,
            }
        case productsConstants.GET_STORE_LIST_SUCCESS:
            return {
                ...state,
                getStoreList_requesting: false, getStoreList_status: true,
                storeList: action.details.data
            }
        case productsConstants.GET_STORE_LIST_FAILURE:
            return {
                ...state,
                getStoreList_requesting: false, getStoreList_status: false, 
                storeList: undefined,
            }

        default:
            return state;
    }
}