import { connect } from 'react-redux';

import { advertisementActions, storesActions, couponsActions, countryActions } from 'Dataflow/Actions';

import StoresPresentation from './StoresPresentation';

function mapStateToProps(state) {
    const { getStoreAdvertisementDetails_requesting, getStoreAdvertisementDetails_status,
            storeAdvertisementDetails } = state.advertisementReducers;

    const { getStores_requesting, getStores_status, storeList, storeList_metadata: metadata } = state.storesReducers;

    const { countryDetails, 
            getCountryList_requesting, countryList } = state.countryReducers;

	return {
        getStoreAdvertisementDetails_requesting, 
        getStoreAdvertisementDetails_status,
        storeAdvertisementDetails,

        getStores_requesting, 
        getStores_status,
        storeList,
        metadata,

        countryDetails,

        getCountryList_requesting,
        countryList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getStoreAdvertisementDetails: (storeId, advertisementNo, advertisementId) => { dispatch(advertisementActions.getStoreAdvertisementDetails(storeId, advertisementNo, advertisementId)); },
        getStores: (idx, storeName, offset, pageSize, isoCode) => { dispatch(storesActions.getStores(idx, storeName, offset, pageSize, isoCode)); },
        getCoupons: (idx, storeName, offset, affiliateType, pageSize, isoCode) => { dispatch(couponsActions.getCoupons(idx, storeName, offset, affiliateType, pageSize, isoCode)); },

        getCountryDetails: (isoCode) => { dispatch(countryActions.getCountryDetails(isoCode)); },
        getCountryList: () => { dispatch(countryActions.getCountryList()); },
    };
}

const Stores = connect(mapStateToProps, mapDispatchToProps)(StoresPresentation);

export default Stores;