export const productsConstants = {
    GET_PRODUCT_SUGGESTIONS_REQUESTING: "GET_PRODUCT_SUGGESTIONS_REQUESTING",
    GET_PRODUCT_SUGGESTIONS_SUCCESS: "GET_PRODUCT_SUGGESTIONS_SUCCESS",
    GET_PRODUCT_SUGGESTIONS_FAILURE: "GET_PRODUCT_SUGGESTIONS_FAILURE",

    GET_PRODUCT_SEARCH_LIST_REQUESTING: "GET_PRODUCT_SEARCH_LIST_REQUESTING",
    GET_PRODUCT_SEARCH_LIST_SUCCESS: "GET_PRODUCT_SEARCH_LIST_SUCCESS",
    GET_PRODUCT_SEARCH_LIST_FAILURE: "GET_PRODUCT_SEARCH_LIST_FAILURE",

    GET_STORE_LIST_REQUESTING: "GET_STORE_LIST_REQUESTING",
    GET_STORE_LIST_SUCCESS: "GET_STORE_LIST_SUCCESS",
    GET_STORE_LIST_FAILURE: "GET_STORE_LIST_FAILURE",
}