import React, { useState } from 'react';

import { func_validation } from 'Functions';

import 'Scss/Components/pzTooltip.scss';

// FIX SCSS file for other positions.

export function PZTooltip(props) {
    const { className,
            description, 
            isAtLocation_top, isAtLocation_right, 
            isAtLocation_bottom, isAtLocation_left } = props;

    const [ isVisible, setVisibility ] = useState(true); 
    
    let description_formatted = func_validation.isStringTrimNotEmpty(description)? description:"";
    if (func_validation.isStringTrimEmpty(description_formatted)) return "";

    // Position
    let tooltipPosition = "";
    let tooltipPointerPosition = "";
    let tooltipInnerPointerPosition = "";
    if (isAtLocation_top) { 
        tooltipPosition = "pzTooltip_top";
        tooltipPointerPosition = "pzTooltip-pointer_bottom";
        tooltipInnerPointerPosition = "pzTooltip-pointer-inner_bottom";
    }
    else if (isAtLocation_right) { 
        tooltipPosition = "pzTooltip_right";
        tooltipPointerPosition = "pzTooltip-pointer_left";
        tooltipInnerPointerPosition = "pzTooltip-pointer-inner_left";
    }
    else if (isAtLocation_bottom) {
        tooltipPosition = "pzTooltip_bottom";
        tooltipPointerPosition = "pzTooltip-pointer_top";
        tooltipInnerPointerPosition = "pzTooltip-pointer-inner_top";
    }
    else if (isAtLocation_left) { 
        tooltipPosition = "pzTooltip_left";
        tooltipPointerPosition = "pzTooltip-pointer_right";
        tooltipInnerPointerPosition = "pzTooltip-pointer-inner_right";
    }

    if (!isVisible) return "";

    return (
        <div className={"pzTooltip " + 
                        (className? className + " ":"") +
                        (tooltipPosition + " ")}>
            {/* Pointer */}
            <div className={"pzTooltip-pointer " + 
                            tooltipPointerPosition + " "}>
                <div className={"pzTooltip-pointer-inner " +
                                tooltipInnerPointerPosition + " "}/>
            </div>

            {/* Exit Button */}
            <div className="pzTooltip-exit"
                onClick={() => {setVisibility(false); }}
            >
                x
            </div>

            {/* Description */}
            <div className="pzTooltip-description">
                {description_formatted}
            </div>
        </div>
    );
}