export const categoriesConstants = {
    generalCategories,
}

function generalCategories() {
    return [
        {
            text: "All",
            value: "All",
        },
        {
            text: "Auto",
            value: "Auto",
        },
        {
            text: "Books & Educations",
            value: "Books & Educations",
        },
        {
            text: "Business",
            value: "Business",
        },
        {
            text: "Car Rental & Transport",
            value: "Car Rental & Transport",
        },
        {
            text: "Children & Baby",
            value: "Children & Baby",
        },
        {
            text: "Clothing",
            value: "Clothing",
        },
        {
            text: "Computer & Electronics",
            value: "Computer & Electronics",
        },
        {
            text: "Cosmetics",
            value: "Cosmetics",
        },
        {
            text: "Department Stores",
            value: "Department Stores",
        },
        {
            text: "Entertainment",
            value: "Entertainment",
        },
        {
            text: "Family Fun & Costume",
            value: "Family Fun & Costume",
        },
        {
            text: "Financial Services",
            value: "Financial Services",
        },
        {
            text: "Flights",
            value: "Flights",
        },
        {
            text: "Flowers",
            value: "Flowers",
        },
        {
            text: "Food & Drink",
            value: "Food & Drink",
        },
        {
            text: "Games & Toys",
            value: "Games & Toys",
        },
        {
            text: "Gift",
            value: "Gift",
        },
        {
            text: "Glasses",
            value: "Glasses",
        },
        {
            text: "Handbag & Luggage",
            value: "Handbag & Luggage",
        },
        {
            text: "Health",
            value: "Health",
        },
        {
            text: "Hobbies & Collectibles",
            value: "Hobbies & Collectibles",
        },
        {
            text: "Home & Living",
            value: "Home & Living",
        },
        {
            text: "Hotels",
            value: "Hotels",
        },
        {
            text: "Jewelry",
            value: "Jewelry",
        },
        {
            text: "Maternity",
            value: "Maternity",
        },
        {
            text: "Office",
            value: "Office",
        },
        {
            text: "Perfumes",
            value: "Perfumes",
        },
        {
            text: "Pets",
            value: "Pets",
        },
        {
            text: "Shoes",
            value: "Shoes",
        },
        {
            text: "Sports & Fitness",
            value: "Sports & Fitness",
        },
        {
            text: "Telecommunications",
            value: "Telecommunications",
        },
        {
            text: "Watches",
            value: "Watches",
        },
        {
            text: "Web Hosting",
            value: "Web Hosting",
        }
    ];
}