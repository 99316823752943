import React, { useState, useEffect} from 'react';

import { Spinner } from './Spinner';
import { CouponCard } from './CouponCard';
import { LoadMoreButton } from './LoadMoreButton';
 
import { func_validation, func_data, func_url } from '../Functions';

import '../Scss/Components/couponList.scss';

export function CouponList(props) {
    const { getCoupons_requesting, affiliateProgram,
            couponList: couponList_new,
            storeName, defaultCouponImage, defaultCouponLink } = props;

    const [totalCoupons, setTotalCoupons] = useState(0);
    const [listOffset, setListOffset] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [couponList, setCouponList] = useState([]);
    const [isInitRender, setInitRenderStatus] = useState(true);

    useEffect(() => { setInitRenderStatus(true); });

    function getList(offset) {
        const { listID, isListIDRequired, isoCode } = props;

        let offset_formatted = 0;
        if (func_validation.isNumberValid(offset)) offset_formatted = offset;

        let listID_formatted = null;
        if (func_validation.isStringTrimNotEmpty(listID)) listID_formatted = listID;

        if (isListIDRequired &&
            (func_validation.isNullUndefined(listID_formatted) || 
            func_validation.isStringTrimEmpty(listID_formatted))) 
            return;

        if (props.getList) {
            props.getList(null, listID_formatted, offset, affiliateProgram, pageSize, isoCode);
            setListOffset(offset_formatted);
        }
    }
    useEffect(() => {
        getList();
    }, [affiliateProgram]);

    function updateListDetail() {
        const { couponList: couponList_new, couponList_meta, getCoupons_status } = props;

        let totalCoupons = func_validation.getObjectPropertyValue(couponList_meta, "totalAvailable", 0);
        let pageSize = func_validation.getObjectPropertyValue(couponList_meta, "pageSize", 50);

        if (func_validation.isArrayValid(couponList_new) &&
            func_validation.isNotNullUndefined(couponList_new) &&
            func_validation.isBooleanFalseNotNullUndefined(getCoupons_requesting) &&
            !isInitRender) {

            let couponList_formatted = [];
            if (listOffset) couponList_formatted = couponList.concat(couponList_new);
            else couponList_formatted = func_validation.cloneArray(couponList_new);

            setTotalCoupons(totalCoupons);
            setCouponList(couponList_formatted);
            setPageSize(pageSize);
        }
    }
    useEffect(() => {
        updateListDetail();
    }, [getCoupons_requesting]);

    let result = "";
    if (func_validation.isArrayNotEmpty(couponList)) {
        result = couponList.map((couponDetail, idx) => {
            let isActive = func_validation.getObjectPropertyValue(couponDetail, "isActive");
            if (!isActive) return "";

            let imageSrc = func_validation.getObjectPropertyValue(couponDetail, "storeImage");
            if (!func_validation.isStringTrimNotEmpty(imageSrc) &&
                func_validation.isStringTrimNotEmpty(defaultCouponImage)) 
                imageSrc = defaultCouponImage;

            let storeName = func_validation.getObjectPropertyValue(couponDetail, "storeName");
            let discount = func_validation.getObjectPropertyValue(couponDetail, "discount");
            let couponLink = func_validation.getObjectPropertyValue(couponDetail, "couponLink");
            let description = func_validation.getObjectPropertyValue(couponDetail, "description");

            let couponName = func_validation.getObjectPropertyValue(couponDetail, "couponName");
            if (couponName.length > description.length) description = couponName;

            let couponCode = func_validation.getObjectPropertyValue(couponDetail, "couponCode");
            let endDate = func_validation.getObjectPropertyValue(couponDetail, "endDate");
            let isLastItem = idx == couponList.length - 1;

            return (
                <React.Fragment key={idx}>
                    <CouponCard
                        couponCard_imageSrc={imageSrc}
                        couponCard_imageAlt={storeName}
                        couponCard_name={storeName}
                        couponCard_discount={discount}
                        
                        couponCard_description={description}
                        
                        couponCard_couponCode={couponCode}

                        couponCard_urlRedirection={couponLink}

                        couponCard_endDate={endDate}
                    />

                    {isLastItem &&
                    (totalCoupons > (listOffset + pageSize))? (
                        <LoadMoreButton
                            loadContent={getList}
                            offset={listOffset + pageSize}
                            isLoading={getCoupons_requesting}
                        />
                    ):""}
                </React.Fragment>

            );
        });
    }
    // Loading
    else if (getCoupons_requesting){
        result = (
            <Spinner/>
        )
    }
    else if (func_validation.isStringTrimNotEmpty(defaultCouponLink)) {
        result = (
            <CouponCard
                couponCard_imageSrc={defaultCouponImage}
                couponCard_imageAlt={storeName}
                couponCard_name={storeName}
                
                couponCard_description={"Enjoy countless deals from different range of products. More Deals, Less Cash, Save Money."}
                couponCard_urlRedirection={defaultCouponLink}
            />
        );
    }
    // Empty
    else if (func_validation.isArrayEmpty(couponList_new)) {
        result = "No deals current available";
    }

    useEffect(() => { setInitRenderStatus(false); });

    return(
        <div className={"couponList text-center"}>
            {result}
        </div>
    );
}