import React from 'react';
import { useParams } from "react-router-dom";

import { CouponList, GeneralLayout } from 'Components';

import { func_validation } from 'Functions';

import 'Scss/Modules/coupons.scss';



export function CouponsPresentation(props) {
    const { getCoupons_requesting, getCoupons_status,
            couponList, couponList_meta,
            countryDetails, 
            getCountryList_requesting, countryList,
            history } = props;

    let { isoCode } = useParams();
    isoCode = func_validation.isStringTrimNotEmpty(isoCode)? isoCode: "WW";

    return (
        <GeneralLayout
            pageHeaderText={"Coupons"}

            countryList={countryList}
            countryDetails={countryDetails}
            getCountryList_requesting={getCountryList_requesting}
            getCountryList={props.getCountryList}
            
            getCountryDetails={props.getCountryDetails}

            isoCode={isoCode}

            mainUrlPathname={"/coupons/"}
            history={history}

            isCategoryDropdownHidden isAdvertisementHidden
        >
            <div className="coupons d-flex flex-column align-items-center">
                {/* Result */}
                <div className={"coupons-body d-flex justify-content-center flex-column"}>
                    <CouponList
                        history={history}

                        updateListPropName_requesting={"getCoupons_requesting"}
                        getCoupons_requesting={getCoupons_requesting}
                        updateListPropName_status={"getCoupons_status"}
                        getCoupons_status={getCoupons_status}

                        isoCode={isoCode}

                        couponList={couponList}
                        couponList_meta={couponList_meta}

                        getList={props.getCoupons}
                    />
                </div>
            </div>
        </GeneralLayout>
    );
}