import { connect } from 'react-redux';

import { sidebarActions } from 'Dataflow/Actions';

import SidebarPresentation from './SidebarPresentation';

function mapStateToProps(state) {
    const { isSidebarVisible } = state.sidebarReducers;

	return {
        isSidebarVisible
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleSidebarVisibility: () => { dispatch(sidebarActions.toggleSidebarVisibility()); }
    };
}

const Sidebar = connect(mapStateToProps, mapDispatchToProps)(SidebarPresentation);

export default Sidebar;