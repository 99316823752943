import { productsConstants } from '../Constants';
import { func_url, func_validation } from '../../Functions';

export const productsActions = {
	getProductSuggestions,
	getProductSearchList,
	getStoreList,
};

function getProductSuggestions(searchTerm, sizeLimit) {
    return dispatch => {
        dispatch(request());

		const axios = require('../../../node_modules/axios');
		
		let param = "";
		if (func_validation.isStringTrimNotEmpty(searchTerm)) param += "searchTerm=" + encodeURIComponent(searchTerm);
		if (func_validation.isNumberValid(sizeLimit)) param += "&sizeLimit=" + encodeURIComponent(sizeLimit);

        axios.get('/api/ProductSearchWebsites/getProductSearchSuggestions.php?' + param)
			.then(function (details) {
				// handle success
				dispatch(success(details));
			})
			.catch(function (error) {
				// handle error
				dispatch(failure(error));
			})
			.finally(function () {
				// always executed
			});
    }

    function request(details) { return { type: productsConstants.GET_PRODUCT_SUGGESTIONS_REQUESTING, details }; }
    function success(details) { return { type: productsConstants.GET_PRODUCT_SUGGESTIONS_SUCCESS, details }; }
    function failure(error) { return { type: productsConstants.GET_PRODUCT_SUGGESTIONS_FAILURE, error }; }
}

function getProductSearchList(searchOptions, prependProductList) {
    return dispatch => {
		dispatch(request());
		
		const axios = require('../../../node_modules/axios');

		let searchOptions_formatted = {};
		if (func_validation.isObjectNotEmpty(searchOptions)) searchOptions_formatted = searchOptions;
		
		let isoCode = "";
		let selectedStores = {};
		let sortType = "";
		let minPrice = 0;
		let maxPrice = 99999999;
		if (func_validation.isObjectNotEmpty(searchOptions_formatted)) {
			isoCode = func_validation.getObjectPropertyValue(searchOptions_formatted, "isoCode","");
			selectedStores = func_validation.getObjectPropertyValue(searchOptions_formatted, "selectedStores", {});
			sortType = func_validation.getObjectPropertyValue(searchOptions_formatted, "sortType", "Lowest");
			minPrice = func_validation.getObjectPropertyValue(searchOptions_formatted, "minPrice", 0);
			maxPrice = func_validation.getObjectPropertyValue(searchOptions_formatted, "maxPrice", 99999999);
		}

		let prependProductList_formatted = [];
		if (func_validation.isArrayNotEmpty(prependProductList))
			prependProductList_formatted = func_validation.cloneArray(prependProductList);


		// Find Stores
		let isStoreFound_cj = false, 
		isStoreFound_ezbuy = false, isStoreFound_lazada = false, isStoreFound_lelong = false, 
		isStoreFound_pgMall = false, isStoreFound_prestomall = false, isStoreFound_shopee = false, 
		isStoreFound_youbeli = false;
		if (func_validation.isArrayNotEmpty(selectedStores)) {
			for (let i = 0; i < selectedStores.length; i++) {
				let affiliateType = func_validation.getObjectPropertyValue(selectedStores[i], "affiliateType");
				let storeName = func_validation.getObjectPropertyValue(selectedStores[i], "name");

				if (affiliateType == "cj") isStoreFound_cj = true;

				switch (storeName) {
					case "ezbuy":
						isStoreFound_ezbuy = true;
						break;
					case "lazada":
						isStoreFound_lazada = true;
						break;
					case "lelong":
						isStoreFound_lelong = true;
						break;
					case "pgMall":
						isStoreFound_pgMall = true;
						break;
					case "prestomall":
						isStoreFound_prestomall = true;
						break;
					case "shopee":
						isStoreFound_shopee = true;
						break;
					case "youbeli":
						isStoreFound_youbeli = true;
						break;
					default:
						break;
				}
			}
		}

		let axiosArray = [];
		let totalRequests = 1;
		if (isoCode == "MY") {
			if ((isStoreFound_ezbuy || func_validation.isObjectEmpty(selectedStores)) &&
				(totalRequests < 7)) {

				let searchOptions_new = func_validation.cloneObject(searchOptions_formatted);
				searchOptions_new.selectedStores = "ezbuy";
				let axiosRequest = axios.post('/api/ProductSearchWebsites/getProductSearchList.php?', searchOptions_new);
				axiosArray.push(axiosRequest);
				totalRequests++;
			}

			if ((isStoreFound_lazada || func_validation.isObjectEmpty(selectedStores)) &&
				(totalRequests < 7)) {

				let searchOptions_new = func_validation.cloneObject(searchOptions_formatted);
				searchOptions_new.selectedStores = "lazada";
				let axiosRequest = axios.post('/api/ProductSearchWebsites/getProductSearchList.php?', searchOptions_new);
				axiosArray.push(axiosRequest);
				totalRequests++;
			}

			if ((isStoreFound_lelong || func_validation.isObjectEmpty(selectedStores)) &&
				(totalRequests < 7)) {

				let searchOptions_new = func_validation.cloneObject(searchOptions_formatted);
				searchOptions_new.selectedStores = "lelong";
				let axiosRequest = axios.post('/api/ProductSearchWebsites/getProductSearchList.php?', searchOptions_new);
				axiosArray.push(axiosRequest);
				totalRequests++;
			}

			if ((isStoreFound_pgMall || func_validation.isObjectEmpty(selectedStores)) &&
				(totalRequests < 7)) {

				let searchOptions_new = func_validation.cloneObject(searchOptions_formatted);
				searchOptions_new.selectedStores = "pgMall";
				let axiosRequest = axios.post('/api/ProductSearchWebsites/getProductSearchList.php?', searchOptions_new);
				axiosArray.push(axiosRequest);
				totalRequests++;
			}

			if ((isStoreFound_prestomall || func_validation.isObjectEmpty(selectedStores)) &&
				(totalRequests < 7)) {

				let searchOptions_new = func_validation.cloneObject(searchOptions_formatted);
				searchOptions_new.selectedStores = "prestomall";
				let axiosRequest = axios.post('/api/ProductSearchWebsites/getProductSearchList.php?', searchOptions_new);
				axiosArray.push(axiosRequest);
				totalRequests++;
			}

			if ((isStoreFound_shopee || func_validation.isObjectEmpty(selectedStores)) &&
				(totalRequests < 7)) {

				let searchOptions_new = func_validation.cloneObject(searchOptions_formatted);
				searchOptions_new.selectedStores = "shopee";
				let axiosRequest = axios.post('/api/ProductSearchWebsites/getProductSearchList.php?', searchOptions_new);
				axiosArray.push(axiosRequest);
				totalRequests++;
			}

			if ((isStoreFound_youbeli || func_validation.isObjectEmpty(selectedStores)) &&
				(totalRequests < 7)) {

				let searchOptions_new = func_validation.cloneObject(searchOptions_formatted);
				searchOptions_new.selectedStores = "youbeli";
				let axiosRequest = axios.post('/api/ProductSearchWebsites/getProductSearchList.php?', searchOptions_new);
				axiosArray.push(axiosRequest);
				totalRequests++;
			}
		}

		if ((isoCode != "MY" || 
				(isoCode == "MY" && func_validation.isArrayNotEmpty(selectedStores))) && 
			(isStoreFound_cj || func_validation.isArrayEmpty(selectedStores)) &&
			(totalRequests < 7)) {

			let axiosRequest = axios.post('/api/ProductSearchWebsites/getProductSearchList.php?', searchOptions_formatted);
			axiosArray.push(axiosRequest);
			totalRequests++;
		}

        axios.all(axiosArray)
			.then(function (details) {
				// Combine all responses
				let newData = {};
				newData.data = [];
				let arraySize = func_validation.getArrayLength(details);
				for (let i = 0; i < arraySize; i++) {
					let data = func_validation.getObjectPropertyValue(details[i], "data");
					if (func_validation.isArrayNotEmpty(data)) newData.data = newData.data.concat(data);					
				}
				newData.data = prependProductList_formatted.concat(newData.data);

				// Sort data
				switch (sortType) {
					case "Lowest":
						newData.data.sort(sort_lowest);
						break;
					case "Highest":
						newData.data.sort(sort_highest);
						break;
					case "Discount":
						newData.data.sort(sort_discount);
						break;
					default:
						break;
				}

				// Price Range
				let filteredData = [];
				for (let i = 0; i < newData.data.length; i++) {
					let currentPrice = func_validation.getObjectPropertyValue(newData.data[i], "currentPrice");

					if (func_validation.isNumberValid(currentPrice) &&
						currentPrice >= minPrice &&
						currentPrice <= maxPrice)
						filteredData.push(newData.data[i]);
				}
				newData.data = func_validation.cloneArray(filteredData);

				// handle success
				dispatch(success(newData));
			})
			.catch(function (error) {
				// handle error
				dispatch(failure(error));
			})
			.finally(function () {
				// always executed
			});
    }

    function request(details) { return { type: productsConstants.GET_PRODUCT_SEARCH_LIST_REQUESTING, details }; }
    function success(details) { return { type: productsConstants.GET_PRODUCT_SEARCH_LIST_SUCCESS, details }; }
    function failure(error) { return { type: productsConstants.GET_PRODUCT_SEARCH_LIST_FAILURE, error }; }
}

function getStoreList(isoCode, category) {
    return dispatch => {
		dispatch(request());
		
		let isoCode_formatted = "";
		if (func_validation.isStringTrimNotEmpty(isoCode)) isoCode_formatted = isoCode;
		let category_formatted = "";
		if (func_validation.isStringTrimNotEmpty(category)) category_formatted = category;

		const axios = require('../../../node_modules/axios');

        axios.get(`/api/ProductSearchWebsites/getStoreList.php?isoCode=${isoCode_formatted}&category=${encodeURIComponent(category_formatted)}`)
			.then(function (details) {
				// handle success
				dispatch(success(details));
			})
			.catch(function (error) {
				// handle error
				dispatch(failure(error));
			})
			.finally(function () {
				// always executed
			});
    }

    function request(details) { return { type: productsConstants.GET_STORE_LIST_REQUESTING, details }; }
    function success(details) { return { type: productsConstants.GET_STORE_LIST_SUCCESS, details }; }
    function failure(error) { return { type: productsConstants.GET_STORE_LIST_FAILURE, error }; }
}

function sort_lowest(object1, object2) {
	return object1.currentPrice > object2.currentPrice? 1:-1; 
} 
function sort_highest(object1, object2) {
	return object1.currentPrice < object2.currentPrice? 1:-1;
}
function sort_discount(object1, object2) {
	return object1.discountPercentage < object2.discountPercentage? 1:-1;
} 