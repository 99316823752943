import { connect } from 'react-redux';

import { storesActions, productsActions, sidebarActions } from 'Dataflow/Actions';

import NavigationBarPresentation from './NavigationBarPresentation';

function mapStateToProps(state) {
    const { storeNameList } = state.storesReducers;
    const { productNameList } = state.productsReducers;

	return {
        storeNameList,

        productNameList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getStoreNameList: (searchTerm, sizeLimit, isoCode) => { dispatch(storesActions.getStoreNameList(searchTerm, sizeLimit, isoCode)); },
        getProductSuggestions: (searchTerm, sizeLimit) => { dispatch(productsActions.getProductSuggestions(searchTerm, sizeLimit)); },

        toggleSidebarVisibility: () => { dispatch(sidebarActions.toggleSidebarVisibility()); }
    };
}

const NavigationBar = connect(mapStateToProps, mapDispatchToProps)(NavigationBarPresentation);

export default NavigationBar;