import { connect } from 'react-redux';

import { advertisementActions } from 'Dataflow/Actions';

import TopBarPromptPresentation from './TopBarPromptPresentation';

function mapStateToProps(state) {
    const { isTopBarPromptVisible } = state.advertisementReducers;

	return {
        isTopBarPromptVisible
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleTopBarPromptVisibility: () => { dispatch(advertisementActions.toggleTopBarPromptVisibility()); }
    };
}

const TopBarPrompt = connect(mapStateToProps, mapDispatchToProps)(TopBarPromptPresentation);

export default TopBarPrompt;