import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import { PZButton } from './PZButton';

import { func_url, func_validation } from '../Functions';

import logo_mini from '../Images/app_logo_mini.svg';

import '../Scss/Components/couponCard.scss';


export function CouponCard(props) {
    // Props
    const { couponCard_couponCode } = props;

    // States
    const [isDescriptionDetailHidden, setIsDescriptionDetailHidden] = useState(true);
    const [isCodeCopied, setIsCodeCopied] = useState(false);

    let name = func_validation.getObjectPropertyValue(props, "couponCard_name");
    if (!func_validation.isStringTrimNotEmpty(name)) name = "Coupon";

    // Discount
    let discount = func_validation.getObjectPropertyValue(props, "couponCard_discount");
    if (!func_validation.isStringTrimNotEmpty(discount)) discount = "";
    else { discount = (
            <div>
                <div className={"couponCard-overviewDiscountText"}>Up to </div>
                {discount}
                <span className={"couponCard-overviewDiscountText"}> OFF</span>
            </div>
        );
    }

    let imageSrc = func_validation.getObjectPropertyValue(props, "couponCard_imageSrc");
    if (func_validation.isStringTrimNotEmpty(imageSrc)) imageSrc = imageSrc.replace("https:", "http:");
    if (func_validation.isStringTrimEmpty(imageSrc)) imageSrc = logo_mini;

    let imageAlt = func_validation.getObjectPropertyValue(props, "couponCard_imageAlt");
    if (func_validation.isStringTrimEmpty(imageAlt)) imageAlt = "Coupon Image";

    let description = func_validation.getObjectPropertyValue(props, "couponCard_description");
    if (func_validation.isStringTrimEmpty(description)) description = "No description.";

    let actionButtonLabel = func_validation.getObjectPropertyValue(props, "couponCard_actionButtonLabel");
    if (func_validation.isStringTrimEmpty(actionButtonLabel) ||
        func_validation.isNullUndefined(actionButtonLabel)) 
        actionButtonLabel = "Get Deal";

    let endDate = func_validation.getObjectPropertyValue(props, "couponCard_endDate", "");

    let totalDaysLeft = -1;
    if (func_validation.isStringTrimNotEmpty(endDate)) {
        let endDate_miliSec = Date.parse(endDate);
        let currentDate_miliSec = Date.parse(new Date());
        
        let totalMiliSecondsLeft = endDate_miliSec - currentDate_miliSec;
        let daysConvertionValue = 1000 * 60 * 60 * 24;
        totalDaysLeft = Math.floor(totalMiliSecondsLeft / daysConvertionValue);
    }

    let expirationDateMessage = "";
    if (totalDaysLeft === 0) expirationDateMessage = "Expires Today"
    else if (totalDaysLeft > 0) expirationDateMessage = "Expires in " + totalDaysLeft + " days";
    else expirationDateMessage = "No Expiry Date";

    let couponCode_formatted = (func_validation.isStringTrimNotEmpty(couponCard_couponCode) && 
                                !func_validation.isStringEqual_caseInsensitive(couponCard_couponCode, "NOT REQUIRED"))? couponCard_couponCode: "CODE NOT REQUIRED";       

    function handleRedirection() {
        const { couponCard_urlRedirection } = props;

        if (func_validation.isFunction(props.handleRedirection)) props.handleRedirection();
        else if (func_validation.isStringTrimNotEmpty(couponCard_urlRedirection))
            func_url.openUrlInNewTab(couponCard_urlRedirection);
    }

    function toggleDescriptionDetailVisiblity() {
        setIsDescriptionDetailHidden(!isDescriptionDetailHidden);
    }

    function copyCouponCode(e) {
        const { couponCard_couponCode } = props;

        // Copy Code
        let inputElement = document.createElement("input");
        document.body.appendChild(inputElement);
        inputElement.value = couponCard_couponCode;
        inputElement.select();
        inputElement.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(inputElement);

        // Display Copied message
        setIsCodeCopied(true);

        // Hide copied message after some period
        setTimeout(() => { setIsCodeCopied(false); }, 1500);
    }
    
    return(
        <div className={"couponCard"}>
            <div className={"couponCardWrapper shadow"}>
                <div className={"row"}>
                    {/* Overview */}
                    <div className={"couponCard-overview col-12 col-sm-3 text-center"}>

                        {/* Coupon Image */}
                        <div className={"couponCard-overviewImage"}>
                            <img
                                id={"couponImage"}
                                src={imageSrc}
                                alt={imageAlt}
                            />
                        </div>
                        
                        {/* Coupon Name */}
                        <div className={"couponCard-overviewName"}>
                            <span>
                                {name}
                            </span>
                        </div>

                        {/* Coupon Discount */}
                        <div className={"couponCard-overviewDiscount"}>
                            {discount}
                        </div>

                    </div>


                    {/* Details */}

                    <div className={"couponCard-detailsDescription col-12 col-sm-6"}>

                        {/* Trigger Visibility */}
                        <div className={"couponCard-detailsDescriptionTrigger text-center d-sm-none"}
                            onClick={toggleDescriptionDetailVisiblity}
                        >
                            {isDescriptionDetailHidden? "View Details": "Hide Details"}
                        </div>

                        {/* Description */}
                        <div className={"couponCard-detailsDescriptionDetails d-sm-block " + 
                                        (isDescriptionDetailHidden? "d-none ":"")}>
                            {description}
                        </div>

                        {/* Code Requirement */}
                        {/* <div className={"couponCard-detailsCodeRequirement"}>
                            * {codeRequirementText}
                        </div> */}

                    </div>


                    {/* Call-to-Action */}
                    <div className={"col-12 col-sm-3"}>
                        
                        {/* Coupon Code */}
                        <div className="couponCard-couponCodeWrapper d-flex align-items-center justify-content-center">
                            {(!isCodeCopied)? (
                                <div className="couponCard-couponCode flex-fill">
                                    {couponCode_formatted}
                                </div>
                            ):(
                                <div className="couponCard-couponCodeCopiedMsg flex-fill">
                                    {"COPIED!"}
                                </div>
                            )}

                            <PZButton
                                pzButton_className={"couponCard-couponCodeCopyFunction"}
                                pzButton_onClick={copyCouponCode}
                                pzButton_isSecondary
                            >
                                <FontAwesomeIcon className={"couponCard-couponCodeCopyFunctionIcon"} icon={faCopy} />
                            </PZButton>

                        </div>

                        {/* Buttton */}
                        <PZButton
                            pzButton_className={"couponCard-callToActionButton"}
                            pzButton_onClick={handleRedirection}
                            pzButton_isSecondary
                        >
                            {actionButtonLabel}
                        </PZButton>

                        {/* Expiration (Days Left) */}
                        <div className={"couponCard-callToActionExpiration text-center"}>
                            <span>
                                {expirationDateMessage}
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}