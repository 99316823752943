export const storesConstants = {
    GET_STORE_NAME_LIST_REQUESTING: "GET_STORE_NAME_LIST_REQUESTING",
    GET_STORE_NAME_LIST_SUCCESS: "GET_STORE_NAME_LIST_SUCCESS",
    GET_STORE_NAME_LIST_FAILURE: "GET_STORE_NAME_LIST_FAILURE",
    
    GET_STORES_REQUESTING: "GET_STORES_REQUESTING",
    GET_STORES_SUCCESS: "GET_STORES_SUCCESS",
    GET_STORES_FAILURE: "GET_STORES_FAILURE",

    GET_STORE_DETAILS_REQUESTING: "GET_STORE_DETAILS_REQUESTING",
    GET_STORE_DETAILS_SUCCESS: "GET_STORE_DETAILS_SUCCESS",
    GET_STORE_DETAILS_FAILURE: "GET_STORE_DETAILS_FAILURE",

    GET_STORE_DETAILS_WITH_LIST_REQUESTING: "GET_STORE_DETAILS_WITH_LIST_REQUESTING",
    GET_STORE_DETAILS_WITH_LIST_SUCCESS: "GET_STORE_DETAILS_WITH_LIST_SUCCESS",
    GET_STORE_DETAILS_WITH_LIST_FAILURE: "GET_STORE_DETAILS_WITH_LIST_FAILURE",
}