import { storesConstants } from '../Constants';
import { func_url, func_validation } from '../../Functions';

export const storesActions = {
	getStoreNameList,

	getStores,
	getStoreDetails,
};

function getStoreNameList(storeName, sizeLimit, isoCode) {
    return dispatch => {
        dispatch(request());

		const axios = require('../../../node_modules/axios');
		
		let param = "";
		if (func_validation.isStringTrimNotEmpty(storeName)) param += "storeName=" + encodeURIComponent(storeName);
		if (func_validation.isNumberValid(sizeLimit)) param += "&sizeLimit=" + encodeURIComponent(sizeLimit);
		if (func_validation.isStringTrimNotEmpty(isoCode)) param += "&isoCode=" + encodeURIComponent(isoCode);

		axios.get('/api/getStoreNameList.php?' + param)
			.then(function (details) {
				// handle success
				dispatch(success(details));
			})
			.catch(function (error) {
				// handle error
				dispatch(failure(error));
			})
			.finally(function () {
				// always executed
			});
    }

    function request(details) { return { type: storesConstants.GET_STORE_NAME_LIST_REQUESTING, details }; }
    function success(details) { return { type: storesConstants.GET_STORE_NAME_LIST_SUCCESS, details }; }
    function failure(error) { return { type: storesConstants.GET_STORE_NAME_LIST_FAILURE, error }; }
}

function getStores(idx, storeName, offset, pageSize, isoCode) {
    return dispatch => {
        dispatch(request());

        const axios = require('../../../node_modules/axios');

		let param = "";
		if (func_validation.isStringTrimNotEmpty(idx) ||
			func_validation.isNumberValid(idx)) param += "categoryIdx=" + encodeURIComponent(idx);
		if (func_validation.isStringTrimNotEmpty(storeName)) param += "&storeName=" + encodeURIComponent(storeName);
		if (func_validation.isNumberValid(offset)) param += "&offset=" + encodeURIComponent(offset);
		if (func_validation.isNumberValid(pageSize)) param += "&pageSize=" + encodeURIComponent(pageSize);
		if (func_validation.isStringTrimNotEmpty(isoCode)) param += "&isoCode=" + encodeURIComponent(isoCode);

        axios.get('/api/getStores.php?' + param)
			.then(function (details) {
				// handle success
				dispatch(success(details));
			})
			.catch(function (error) {
				// handle error
				dispatch(failure(error));
			})
			.finally(function () {
				// always executed
			});
    }

    function request(details) { return { type: storesConstants.GET_STORES_REQUESTING, details }; }
    function success(details) { return { type: storesConstants.GET_STORES_SUCCESS, details }; }
    function failure(error) { return { type: storesConstants.GET_STORES_FAILURE, error }; }
}

function getStoreDetails(storeName) {
    return dispatch => {
        dispatch(request());

        const axios = require('../../../node_modules/axios');
		
		let param = "";
		if (func_validation.isStringTrimNotEmpty(storeName)) param += "storeName=" + encodeURIComponent(storeName);

        axios.get('/api/getStoreDetails.php?' + param)
			.then(function (details) {
				// handle success
				dispatch(success(details));
			})
			.catch(function (error) {
				// handle error
				dispatch(failure(error));
			})
			.finally(function () {
				// always executed
			});
    }

    function request(details) { return { type: storesConstants.GET_STORE_DETAILS_REQUESTING, details }; }
    function success(details) { return { type: storesConstants.GET_STORE_DETAILS_SUCCESS, details }; }
    function failure(error) { return { type: storesConstants.GET_STORE_DETAILS_FAILURE, error }; }
}